import React, { useEffect, useState } from 'react';

// CSS Files
import './../App.css';

// Components
import LandingSection from '../components/LandingSection';
import OurVaulesSection from '../components/OurValuesSection';
import ServiceAndProducts from '../components/ServicesAndProductsSection';
import AboutOurTeam from './OurClients';
import ContactUs from '../components/ContactUs';
import { Link, useNavigate } from 'react-router-dom';
import { CreateOrg } from '../Requests/Organisation'
import axios from 'axios';
import { generateSignature, DecodeJWT } from '../Requests/LoginNRegister'
import { FiveSpacer, TenSpacer } from '../styledcomponents/spacers';
import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import { getPackages } from '../Requests/Packages';

// Styled Components

// SVGS

// Icons

// Pages

export default function AirlaVault({ comeRenew = false, selectedOrg = null, selectedPayment }: any) {

    const [selectedItem, setSelectedItem] = useState<any>({ id: 0 });
    const [orgList, setOrgList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCreateOrgOpen, setisCreateOrgOpen] = useState(false);
    const [isExsitingOrgOpen, setisExsitingOrgOpen] = useState(false);
    const [openCreateOrg, setOpenCreateOrg] = useState(false);
    const [orgName, setOrgName] = useState("");
    const [contactPerson, setContactPerson] = useState("");

    const [organisationId, setOrganisationId] = useState<any>(null);

    const [createOrgLoading, setCreateOrgLoading] = useState(false)

    const [paymentSelected, setPaymentSelected] = useState(selectedPayment);
    const [allPackages, setAllPackages] = useState(selectedPayment);

    const nav = useNavigate();

    const handleDropdownSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = event.target.value;
        const selectedItem: any = orgList.find((item: any) => item.id.toString() === selectedId);
        if (selectedId === "0") {
            setSelectedItem(selectedItem);

            return
        }

        setSelectedItem(selectedItem);
        // console.log(selectedItem)
        setIsOpen(false);
        // getOrgList();
        if (selectedItem && paymentSelected) {
            myData.custom_str1 = selectedItem.id.toString();
            myData.custom_str2 = paymentSelected.id.toString()
            myData.item_name = selectedItem.name + " - " + paymentSelected.name;
            myData.recurring_amount = paymentSelected.reccuring_amount
            setMyData(myData); // Assuming myData is a stateful variable
            const myPassphrase = process.env.REACT_APP_API_MERCHANT_PASSPHRASE;
            let genData = await generateSignature(myData, myPassphrase);
            // console.log("genData", myData);
            // console.log("genData", genData);
            setMyData((prevData) => ({
                ...prevData,
                signature: genData,
            }));

        }
    };

    useEffect(() => {
        getOrgList();
        JTWDecodeFullData();
        if (comeRenew) {
            getAllPackages()
        }

    }, []);

    const [myData, setMyData] = useState({
        merchant_id: process.env.REACT_APP_API_MERCHANT_ID,
        merchant_key: process.env.REACT_APP_API_MERCHANT_KEY,
        return_url: 'https://www.airlaforge.co.za/',
        cancel_url: 'https://www.airlaforge.co.za/',
        notify_url: `${process.env.REACT_APP_API_URL_FMS}v1/payments/webhook`,
        name_first: '',
        name_last: '',
        email_address: '',
        m_payment_id: '',
        amount: paymentSelected && paymentSelected.price,
        item_name: '',
        custom_str1: '',
        custom_str2: paymentSelected && paymentSelected.id.toString(),
        subscription_type: '1',
        billing_date: '2000-01-01',
        recurring_amount: paymentSelected && paymentSelected.reccuring_amount,
        frequency: '3',
        cycles: '0'
    });

    //sandbox.payfast.co.za/eng/process
    const [pfHost] = useState(process.env.REACT_APP_PAYFAST_URL);

    const showCreateOrg = () => {
        setisCreateOrgOpen(true);
        setisExsitingOrgOpen(false);
        setOpenCreateOrg(true);
    };

    const showExsitingOrg = () => {
        setisCreateOrgOpen(false);
        setisExsitingOrgOpen(true);
    };

    async function getAllPackages() {
        let responseData = await getPackages();
        if (responseData) {
            // console.log(responseData);
            setAllPackages(responseData)

        }
    }
    //Only Gets used when paying from subscriptions page
    async function SelectPaymentOption(option: any) {
        setPaymentSelected(option);
        myData.amount = option.price
        myData.custom_str1 = selectedItem.id.toString();
        myData.custom_str2 = option.id.toString()
        myData.item_name = selectedItem.name + " - " + option.name;
        myData.recurring_amount = option.reccuring_amount
        setMyData(myData); // Assuming myData is a stateful variable
        const myPassphrase = process.env.REACT_APP_API_MERCHANT_PASSPHRASE;
        let temp: any = myData;
        delete temp.signature
        let genData = await generateSignature(myData, myPassphrase);
        // console.log("genData", myData);
        // let temp: any = myData;
        // temp.signature = genData
        setMyData((prevData) => ({
            ...prevData,
            signature: genData,
        }));
    }

    return (
        <>
            <div className="optionSelectFlex">
                {!comeRenew && <><button className='SelectOptionButton' onClick={(showExsitingOrg)}>Select An Organisation</button>
                    <button className='SelectOptionButton' onClick={(showCreateOrg)}>Create an Organisation</button>
                </>}
            </div>
            <hr className="hrstyling" />
            {isExsitingOrgOpen && <>
                <span className="PaymentPackageTextHeader">Selected Organisation</span>
                <TenSpacer />

                <select disabled={comeRenew} id="dropdown" className='InquiryDropwdownInput' value={selectedItem.id} onChange={handleDropdownSelect}>

                    {orgList.length > 0 && orgList.map((item: any, index: any) => {
                        return (
                            <>
                                {item.expired && <option className='InquiryOptions' key={item.id} value={item.id}>{item.name}</option>}
                            </>
                        )
                    })}
                </select>

            </>}
            {isCreateOrgOpen && <>
                <span className="PaymentSpan">Create An Organisation</span>
                <TenSpacer />
                {openCreateOrg &&
                    <>
                        <div className="optionSelectFlex">
                            <input
                                id="orgNameInput"
                                className='InquiryInput'
                                placeholder="Enter the Organisation Name"
                                value={orgName}
                                onChange={(e) => setOrgName(e.target.value)}
                            />
                            <input
                                id="orgNameInput"
                                className='InquiryInput'
                                placeholder="Enter Contact Person Email"
                                value={contactPerson}
                                onChange={(e) => setContactPerson(e.target.value)}
                            />
                        </div>
                        <button className='CreateOrgButton' onClick={createOrg}>Create</button>
                        <FiveSpacer />
                        {createOrgLoading && <LinearProgress variant="query" />}
                    </>}
            </>}
            <span className='PaymentPackageTextHeader'>Please select a package below</span>
            <TenSpacer />
            {comeRenew && <div className='paymentPackageOptionV2'>
                {allPackages && allPackages.map((packag: any) => {
                    return (<>
                        <div onClick={() => { SelectPaymentOption(packag); }}
                            className={paymentSelected && paymentSelected.id === packag.id ? "paymentPackageOptionBlockSelected" : 'paymentPackageOptionBlock'}>

                            <>{packag.name}</>
                            <br />
                            <br />
                            <>Price: R{packag.price}</>
                        </div>
                        <TenSpacer />

                    </>)
                })}

            </div>}

            <form className='formCss' action={`https://${pfHost}/eng/process`} method="post">
                <TenSpacer />
                <span className="PaymentPackageTextHeader">Your account details</span>
                <TenSpacer />
                <div className='PaymentDetailsFlex'>
                    {Object.entries(myData).map(([key, value]) => {

                        if (key === "name_first") {
                            // console.log("Form", myData);

                        }
                        if (value !== "") {
                            if (key === "name_first" || key === "name_last" || key === "email_address") {
                                return (<><input className='DetailsInput'
                                    key={key}
                                    name={key}
                                    type="text"
                                    value={value}
                                /></>)
                            } else {

                                return (
                                    <input className='DetailsInput '
                                        key={key}
                                        name={key}
                                        type="hidden"
                                        value={value}
                                    />
                                );
                            }
                        }

                        return null;
                    })}
                </div>
                {selectedItem.id === 0 || !paymentSelected ? <GreyedPaymentBtn disabled> No Organisation </GreyedPaymentBtn> :
                    <GreenPaymentBtn type="submit" value="Procced To Payment"> Proceed to Pay </GreenPaymentBtn>}
            </form>
        </>
    );

    async function getOrgList() {

        let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/corp/`
        let token = localStorage.getItem("Auth");
        let options = {
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${token}`
            }
        };
        try {

            const response: any = await axios.get(endpoint, options);
            if (response.data && response.data.statusCode === 0) {
                response.data.user.unshift({ id: 0, name: "Select an organisation", paid_until: null, noPay: true })

                setSelectedItem(response.data.user[0])
                setOrgList(response.data.user);

                if (!comeRenew && !selectedOrg) {
                    for (let i = 0; i < response.data.user.length; i++) {
                        // item.expired = false;
                        response.data.user[i].expired = false

                        if (response.data.user[i].paid_until === null || (Date.parse(response.data.user[i].paid_until) < Date.now()) || typeof response.data.user[i].noPay !== 'undefined') {
                            response.data.user[i].expired = true;
                        }
                    }


                }

                // console.log(response.data.user)
                if (comeRenew && selectedOrg) {
                    setisExsitingOrgOpen(true);

                    const selectedItem: any = response.data.user.find((item: any) => item.id === selectedOrg);
                    // console.log(selectedOrg, "found", selectedItem)
                    console.log("================= Came from use Dashboard =================", selectedItem)
                    if (selectedItem) {
                        selectedItem.expired = true
                        setSelectedItem(selectedItem);
                        if (paymentSelected) {

                            myData.amount = paymentSelected.price
                            myData.custom_str1 = selectedItem.id.toString();
                            myData.custom_str2 = paymentSelected.id.toString()

                            myData.item_name = selectedItem.name + " - " + paymentSelected.name;
                            myData.recurring_amount = paymentSelected.reccuring_amount
                            setMyData(myData); // Assuming myData is a stateful variable
                            const myPassphrase = process.env.REACT_APP_API_MERCHANT_PASSPHRASE;
                            let genData = await generateSignature(myData, myPassphrase);
                            // console.log("genData", genData);
                            setMyData((prevData) => ({
                                ...prevData,
                                signature: genData,
                            }));
                        }
                    }
                }

            } else {
                // setOrgList(null);

            }
        } catch (e: any) {
            nav('/onboarding')
        }

    }
    async function createOrg() {
        setCreateOrgLoading(true)
        let temp = await CreateOrg(orgName, contactPerson)
        console.log(temp, paymentSelected);
        if (temp && paymentSelected) {
            setOrganisationId(temp)
            myData.item_name = orgName.toString() + " - " + paymentSelected.name;
            myData.custom_str1 = temp.toString()
            myData.custom_str2 = paymentSelected.id.toString()
            myData.amount = paymentSelected.price
            myData.recurring_amount = paymentSelected.reccuring_amount
            setMyData(myData)
            setSelectedItem({ id: temp, name: orgName.toString(), paid_until: null })

            const myPassphrase = process.env.REACT_APP_API_MERCHANT_PASSPHRASE;
            let genData = await generateSignature(myData, myPassphrase)
            setMyData((prevData) => ({
                ...prevData,
                signature: genData,
            }));
            setCreateOrgLoading(false)
        }
    }
    async function JTWDecodeFullData() {
        let temp: any = await DecodeJWT(localStorage.getItem('Auth'))

        if (temp) {
            myData.name_first = temp.first_name;
            myData.name_last = temp.last_name;
            myData.email_address = temp.email;
            setMyData(myData)
        }

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        myData.billing_date = formattedDate;
        myData.m_payment_id = Date.now().toString();

    }



};

export const GreyedPaymentBtn = styled.button`
 height: 30px;
  background-color: #979797;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
`;

export const GreenPaymentBtn = styled.button`
 height: 30px;
  background-color: #21c400;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
`;

