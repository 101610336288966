import axios from "axios";

export async function CreateOrg(orgName: string, contactPerson: string) {
    let success = false;
    const organizationData = {
        name: orgName,
        contactPerson: contactPerson
    };

    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/corp/`

    let token = localStorage.getItem("Auth");
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };
    await axios.post(endpoint, organizationData, options)
        .then(response => {
            // console.log(response.data.orgId);
            success = response.data.orgId;
        })
        .catch(error => {
            console.log(error.data);
            success = false;
        });

    return success
}

