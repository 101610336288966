import React, { useCallback, useEffect, useRef, useState } from 'react';

// CSS Files
import './../App.css';
import './../AccountVerification.css'

// Components
import LandingSection from '../components/LandingSection';
import OurVaulesSection from '../components/OurValuesSection';
import ServiceAndProducts from '../components/ServicesAndProductsSection';
import AboutOurTeam from '../components/OurClients';
import ContactUs from '../components/ContactUs';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import type { Container, Engine } from "tsparticles-engine";
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as DeniedImg } from '../imgs/Shields Check And Cross.svg'
import { ReactComponent as SucessImg } from '../imgs/Shields Check And Tick.svg'
import { Box, LinearProgress } from '@mui/material';

// Styled Components

// SVGS

// Icons

// Pages

export default function AccountVerification() {
    const [verification_State, setVerification_State] = useState<any>(true);
    const [verification_Text, setVerification_Text] = useState("");
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);

    const [isLoading, setIsLoading] = useState(true);
    const nav = useNavigate();
    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // console.log(container);
    }, []);


    useEffect(() => {
        const observerLeft = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("NavshowLeft")
                } else {
                    entry.target.classList.remove("NavshowLeft")
                }
            })
        })

        const hiddenElementsLeft = document.querySelectorAll(".NavhiddenLeft")
        hiddenElementsLeft.forEach((el) => observerLeft.observe(el))

        const observerBot = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("NavshowBot")
                } else {
                    entry.target.classList.remove("NavshowBot")
                }
            })
        })

        const hiddenElementsBot = document.querySelectorAll(".NavhiddenBot")
        hiddenElementsBot.forEach((el) => observerBot.observe(el))


    }, [])

    async function sendVerification() {
        setIsLoading(true)
        if (!queryParameters.get("token") || !queryParameters.get("token")) {
            setVerification_Text("URL Corrupted");
            setVerification_State(false);
        }
        let domain = process.env.REACT_APP_API_URL_FMS;
        // console.log(domain)
        let endpoint = `${domain}v1/user/verifyaccount/${queryParameters.get("id")}/${queryParameters.get("token")}`;
        const response = await axios.get(endpoint);
        // console.log(response.data)
        if (typeof response.data.statusCode !== 'undefined' && response.data.statusCode === 0) {
            setVerification_Text("Successful");
            setVerification_State(true);
        } else {
            setVerification_Text(response.data.description || "Link Expired");
            setVerification_State(false);
        }
        setIsLoading(false)
    }
    const initialized = useRef(false)


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            sendVerification()

        }
    }, [])

    return (
        <>
            <div className='mainVA accountVerificationPage'>
                <Particles className='particlesAcountVerification'
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}

                    options={{
                        particles: {
                            number: { value: 120, density: { enable: true, value_area: 1000 } },
                            color: { value: "#ffffff" },
                            shape: {
                                type: "circle",
                                stroke: { width: 0, color: "#000000" },
                                polygon: { nb_sides: 5 },
                                image: { src: "img/github.svg", width: 100, height: 100 }
                            },
                            opacity: {
                                value: 1,
                                random: true,
                                anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
                            },
                            size: {
                                value: 2.5,
                                random: true,
                                anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
                            },
                            move: {
                                enable: true,
                                speed: 0.2,
                                direction: "none",
                                random: true,
                                straight: false,
                                out_mode: "out",
                                bounce: false,
                                attract: { enable: false, rotateX: 600, rotateY: 600 }
                            }
                        },
                        retina_detect: true
                    }}
                />
                <div className='accountVerificationContainer'>
                    {isLoading ?
                        <div className='bottomTextContainer'>
                            <div className='bottomTextWhiteBlock'>

                                <h2>Verifying Account</h2>
                                <br /><span> Please wait...</span>
                                <div className='bottomTextGrayBlock'>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress color='inherit' style={{ borderRadius: 10, height: '15px', backgroundColor: 'darkblue', color: '#ff6e61' }} />
                                    </Box>
                                </div>
                            </div>
                        </div> :
                        <>
                            {verification_State === true ?
                                <>
                                    <div className='imgContainer'>

                                        <div className='imgBlock'>
                                            <SucessImg className='verifyImage' />
                                        </div>
                                    </div>
                                    <div className='bottomTextContainer'>
                                        <div className='bottomTextWhiteBlock'>

                                            <span>Account verified, Status:</span>
                                            <div className='bottomTextGrayBlock'>
                                                <span className='textSuccessfull'>"{verification_Text}"</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className='returnHomeBTN' onClick={() => { nav('/') }}>Return Home</button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='imgContainer'>

                                        <div className='imgBlock'>
                                            <DeniedImg className='verifyImage' />
                                        </div>
                                    </div>



                                    <div className='bottomTextContainer'>
                                        <div className='bottomTextWhiteBlock'>

                                            <span>Account could not be verified Due to:</span>
                                            <div className='bottomTextGrayBlock'>
                                                <span className='textError'>"{verification_Text}"</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className='returnHomeBTN' onClick={() => { nav('/') }}>Return Home</button>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div >

            </div >

        </>
    );
};

;