import React, { useState } from 'react';

// CSS Files
import './../App.css';
import NavBar from './NavBar';
import { HundredSpacer, TenSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from './Footer';
import PersonalDetails from './PersonalDetails';
import ContactUs from './ContactUs';
import ContactUsV2 from './ContactUsV2';
import FooterV2 from './FooterV2';
import InquiryModal from './Modals/InquiryModal';

// Components

// Styled Components

// SVGS

// Icons

// Pages

const Revisions: React.FC = () => {
    const [inquirymodalIsOpen, setinquiryModalIsOpen] = useState(false);
    const [productSelected, setProductSelected] = useState("");
    const openModal = () => {
        setinquiryModalIsOpen(true);
    };

    const closeModal = () => {
        setinquiryModalIsOpen(false);
    };
    return (
        <>
            <InquiryModal isOpen={inquirymodalIsOpen} onClose={closeModal} productText={productSelected} />

            <div className="PackagesSectionTabsInner">
                <div className='PackagesFlex'>
                    <div className='PackagesDiv' >
                        <div className='PackagesInnerDiv'>
                            <span className='PackagesHOne'>Basic <br /> <span className='LandingPageHOneOrange'>Package</span>.</span>
                            <TenSpacer />
                            <span className='listStyling'>This package includes the following:</span>
                            <div className="listContainer">

                                <ul className='listStyling'>
                                    <li>Up to 3 pages of website content and design.</li>
                                    <li> Necessary plugins for essential functionality.</li>
                                    <li> Responsive design for optimal display on various devices.</li>
                                    <li>  Basic admin panel for content management.</li>
                                    <li>  Basic website designs and templates.</li>
                                    <li>  Contact form setup.</li>
                                    <li> Basic SEO optimization.</li>
                                    <li>  Hosting recommendations.</li>
                                </ul>
                            </div>
                            <TenSpacer />

                            <button className='EnquireBtn' onClick={() => { openModal(); setProductSelected('Basic ReactJsPackage') }}>Enquire</button>
                        </div>
                    </div>
                    <div className='PackagesDiv'>
                        <div className='PackagesInnerDiv'>
                            <span className='PackagesHOne'>Standard <br /> <span className='LandingPageHOneOrange'>Package</span>.</span>
                            <TenSpacer />
                            <span className='listStyling'>This package includes the following:</span>
                            <div className="listContainer">

                                <ul className='listStyling'>
                                    <li> Up to 6 pages of website content and design.</li>
                                    <li> Necessary and relevant ReactJS plugins and components.</li>
                                    <li> Responsive design with advanced React components for rich user interactions.</li>
                                    <li> Source code for the standard pages.</li>
                                    <li> Customized website designs and templates.</li>
                                    <li> Contact forms with advanced React forms.</li>
                                    <li> Basic SEO optimization with keyword analysis.</li>
                                    <li> Basic security features.</li>
                                    <li> Basic performance optimization.</li>
                                    <li> Hosting recommendations.</li>
                                </ul>
                            </div>
                            <TenSpacer />

                            <button className='EnquireBtn' onClick={() => { openModal(); setProductSelected('Standard ReactJsPackage') }}>Enquire</button>
                        </div>
                    </div>
                    <div className='PackagesDiv'>
                        <div className='PackagesInnerDiv'>
                            <span className='PackagesHOne'>Premium <br /> <span className='LandingPageHOneOrange'>Package</span>.</span>
                            <TenSpacer />
                            <span className='listStyling'>This package includes the following:</span>
                            <div className="listContainer">

                                <ul className='listStyling'>
                                    <li>Customized number of pages based on client needs.</li>
                                    <li> Custom ReactJS components and interactive features.</li>
                                    <li> Highly responsive design and rich user interactions.</li>
                                    <li> Comprehensive source code for advanced pages.</li>
                                    <li>  Tailor-made website designs and templates.</li>
                                    <li>  Customized contact forms and interactive elements with React forms.</li>
                                    <li>Comprehensive SEO optimization with keyword research and strategy.</li>
                                    <li> Advanced security features and regular updates.</li>
                                    <li>Advanced performance optimization.</li>
                                    <li> Hosting recommendations and assistance.</li>
                                    <li> Integration with social media and third-party tools.</li>
                                </ul>
                            </div>
                            <TenSpacer />

                            <button className='EnquireBtn' onClick={() => { openModal(); setProductSelected('Premium ReactJsPackage') }}>Enquire</button>
                        </div>
                    </div>
                </div >
            </div>
        </>
    );
};

export default Revisions;