import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AirlaLogo } from './../imgs/Group 428.svg';
import { RxHamburgerMenu } from "react-icons/rx";
import './../App.css';
import InquiryModal from './Modals/InquiryModal';

interface MobileNavBarProps {
    isOpen: boolean;
    onClose: () => void;
    onLogout: () => void;
    isLoggedIn: boolean;
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({ isOpen, onClose, onLogout, isLoggedIn }) => {
    const nav = useNavigate();

    return (
        <div className={`MobileNavBar ${isOpen ? 'open' : ''}`}>
            <ul className="MobileNavUl UlContainer">
                <div className="NavUlInner">
                    <li className="LiNavMobile">
                        <span onClick={() => { nav("/ourofferings"); onClose() }}>
                            Our Services
                        </span>
                    </li>
                    <li className="LiNavMobile">
                        <span onClick={() => { nav("/ourwork"); onClose() }}>
                            Our Work
                        </span>
                    </li>
                    <li className="LiNavMobile">
                        <span onClick={() => { nav("/aboutus"); onClose() }}>
                            About Us
                        </span>
                    </li>
                    {isLoggedIn ? (
                        <>
                            <li className="LiNavMobile">
                                <span onClick={() => { nav("/myaccount"); onClose() }}>
                                    My Account
                                </span>
                            </li >
                            <li className="LiNavMobile">
                                <span className="NavLi" onClick={onLogout}>
                                    Log Out
                                </span>
                            </li>
                        </>
                    ) : (
                        <li className="LiNavMobile">
                            <span onClick={() => { nav("/onboarding"); onClose() }}>
                                Login
                            </span>
                        </li>
                    )}
                </div>
            </ul>
        </div>
    );
};




export default function NavBar() {
    const [isAnimating, setIsAnimating] = useState(false);
    const [inquirymodalIsOpen, setinquiryModalIsOpen] = useState(false);
    const openModal = () => {
        setinquiryModalIsOpen(true);
    };

    const closeModal = () => {
        setinquiryModalIsOpen(false);
    };

    const nav = useNavigate();
    const location = useLocation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [selectedNav, setSelectedNav] = useState(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const navLinks = [
        { path: '/ourofferings', label: 'Our Services' },
        // { path: '/ourwork', label: 'Our Work' },
        // { path: '/aboutus', label: 'About Us' },
    ];
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        let temp = localStorage.getItem('Auth');
        if (temp) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        const isNavLink = navLinks.some((link) => location.pathname === link.path || "/myaccount");

        if (isNavLink) {
            let pathName: any = location.pathname;
            setSelectedNav(pathName);
        } else {
            setSelectedNav(null);
        }
    }, [location.pathname, navLinks]);

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen((prevIsMobileMenuOpen) => !prevIsMobileMenuOpen);
        // console.log('Mobile menu state:', isMobileMenuOpen);
    };

    const handleMobileMenuClose = () => {
        setIsMobileMenuOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('Auth');
        localStorage.removeItem('AuthR');
        localStorage.removeItem('StayLogin');
        nav('/');
        handleMobileMenuClose();
        window.location.reload();
    };

    return (
        <>
            <InquiryModal isOpen={inquirymodalIsOpen} onClose={closeModal} productText={"Contactus Home"} />

            {/* Large screens */}
            <div className='NavBarFlex desktop-nav NavhiddenLeft'>
                <Link to={'/'}>
                    <AirlaLogo className='AirlaLogo' path='/' />
                </Link>
                <div className='NavBarRightDiv'>
                    <ul className='NavUl'>
                        {navLinks.map((link, index) => (
                            <li key={index}>
                                <Link className={`NavLi ${selectedNav === link.path ? 'NavLiSelected' : ''}`} to={link.path}>
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                        {isLoggedIn ? (
                            <>
                                <li key={5555}>
                                    <Link className={`NavLi ${selectedNav === "/myaccount" ? 'NavLiSelected' : ''}`} to={'/myaccount'}>
                                        My Account
                                    </Link>
                                </li>
                                <li key={55555}>
                                    <span
                                        className='NavLi'
                                        onClick={() => {
                                            localStorage.removeItem('Auth');
                                            localStorage.removeItem('AuthR');
                                            localStorage.removeItem('StayLogin');
                                            nav('/');
                                            window.location.reload();
                                        }}
                                    >
                                        Log Out
                                    </span>
                                </li>
                            </>
                        ) : (
                            <li key={555555}>
                                <Link className={`NavLi ${selectedNav === "/onboarding" ? 'NavLiSelected' : ''}`} to={'/onboarding'}>
                                    Login
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
                <button className='ContactUsBtn' onClick={openModal}>Contact us</button>
            </div>

            {/* Mobile screens - Hamburger Menu */}
            <div className='MobileNavBarFlex mobile-nav'>
                <div className='MobileNavFlex'>
                    <Link to={'/'}>
                        <AirlaLogo className='AirlaLogo' path='/' />
                    </Link>
                    <div className='MobilePosRel'>
                        <RxHamburgerMenu className="MobileMenuToggle HamburgerMenu" onClick={handleMobileMenuToggle}>
                            &#10005;
                        </RxHamburgerMenu>
                        <div className={`MobileNavBar ${isMobileMenuOpen ? 'open' : ''}`}>
                            <div>
                                <MobileNavBar
                                    isOpen={isMobileMenuOpen}
                                    onClose={handleMobileMenuClose}
                                    onLogout={handleLogout}
                                    isLoggedIn={isLoggedIn}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// export default function NavBar() {

//     const [inquirymodalIsOpen, setinquiryModalIsOpen] = useState(false);
//     const openModal = () => {
//         setinquiryModalIsOpen(true);
//     };

//     const closeModal = () => {
//         setinquiryModalIsOpen(false);
//     };

//     const nav = useNavigate();
//     const location = useLocation();
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//     const [selectedNav, setSelectedNav] = useState(null);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     const navLinks = [
//         { path: '/ourofferings', label: 'Our Services' },
//         { path: '/ourwork', label: 'Our Work' },
//         { path: '/aboutus', label: 'About Us' },
//     ];
//     const [isLoggedIn, setIsLoggedIn] = useState(false);

//     useEffect(() => {
//         let temp = localStorage.getItem('Auth');
//         if (temp) {
//             setIsLoggedIn(true);
//         }
//     }, []);

//     useEffect(() => {
//         const isNavLink = navLinks.some((link) => location.pathname === link.path || "/myaccount");

//         if (isNavLink) {
//             let pathName: any = location.pathname;
//             setSelectedNav(pathName);
//         } else {
//             setSelectedNav(null);
//         }
//     }, [location.pathname, navLinks]);

//     const handleMobileMenuToggle = () => {
//         setIsMobileMenuOpen((prevIsMobileMenuOpen) => !prevIsMobileMenuOpen);
//         // console.log('Mobile menu state:', isMobileMenuOpen);
//     };

//     const handleMobileMenuClose = () => {
//         setIsMobileMenuOpen(false);
//     };

//     const handleLogout = () => {
//         localStorage.removeItem('Auth');
//         localStorage.removeItem('AuthR');
//         localStorage.removeItem('StayLogin');
//         nav('/');
//         handleMobileMenuClose();
//     };

//     return (
//         <>
//             <InquiryModal isOpen={inquirymodalIsOpen} onClose={closeModal} productText={"Contactus Home"} />

//             {/* Large screens */}
//             <div className='NavBarFlex desktop-nav'>
//                 <Link to={'/'}>
//                     <AirlaLogo className='AirlaLogo' path='/' />
//                 </Link>
//                 <div className='NavBarRightDiv'>
//                     <ul className='NavUl'>
//                         {navLinks.map((link, index) => (
//                             <li key={index}>
//                                 <Link className={`NavLi ${selectedNav === link.path ? 'NavLiSelected' : ''}`} to={link.path}>
//                                     {link.label}
//                                 </Link>
//                             </li>
//                         ))}
//                         {isLoggedIn ? (
//                             <>
//                                 <li key={5555}>
//                                     <Link className={`NavLi ${selectedNav === "/myaccount" ? 'NavLiSelected' : ''}`} to={'/myaccount'}>
//                                         My Account
//                                     </Link>
//                                 </li>
//                                 <li key={55555}>
//                                     <span
//                                         className='NavLi'
//                                         onClick={() => {
//                                             localStorage.removeItem('Auth');
//                                             localStorage.removeItem('AuthR');
//                                             localStorage.removeItem('StayLogin');
//                                             nav('/');
//                                         }}
//                                     >
//                                         Log Out
//                                     </span>
//                                 </li>
//                             </>
//                         ) : (
//                             <li key={555555}>
//                                 <Link className={`NavLi ${selectedNav === "/onboarding" ? 'NavLiSelected' : ''}`} to={'/onboarding'}>
//                                     Login
//                                 </Link>
//                             </li>
//                         )}
//                     </ul>
//                     <button className='ContactUsBtn' onClick={openModal}>Contact us</button>
//                 </div>
//             </div>

//             {/* Mobile screens - Hamburger Menu */}
//             <div className='MobileNavBarFlex mobile-nav'>
//                 <div className='MobileNavFlex'>
//                     <Link to={'/'}>
//                         <AirlaLogo className='AirlaLogo' path='/' />
//                     </Link>
//                     <div className='MobilePosRel'>
//                         <RxHamburgerMenu className="MobileMenuToggle HamburgerMenu" onClick={handleMobileMenuToggle}>
//                             &#10005;
//                         </RxHamburgerMenu>
//                         <div className={`MobileNavBar ${isMobileMenuOpen ? 'open' : ''}`}>
//                             <div>
//                                 <MobileNavBar
//                                     isOpen={isMobileMenuOpen}
//                                     onClose={handleMobileMenuClose}
//                                     onLogout={handleLogout}
//                                     isLoggedIn={isLoggedIn}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };
