import React, { useCallback } from 'react';

// CSS Files
import '../App.css';
import '../Particles.css';

// Components
import LandingSection from '../components/LandingSection';
import OurVaulesSection from '../components/OurValuesSection';
import ServiceAndProducts from '../components/ServicesAndProductsSection';
import AboutOurTeam from '../components/OurClients';
import ContactUs from '../components/ContactUs';
import { Link } from 'react-router-dom';
import FooterV2 from '../components/FooterV2';
import NavBar from '../components/NavBar';
import { ReactComponent as TempLeftImage } from './../imgs/UFOnotfoundAnimated.svg';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import type { Container, Engine } from "tsparticles-engine";
// Styled Components

// SVGS

// Icons

// Pages

export default function NotFound() {

    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // console.log(container);
    }, []);

    return (
        <>
            <div className='mainVA' >
                <Particles className='onePageParticles'
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}

                    options={{
                        fullScreen: false,
                        particles: {
                            number: { value: 120, density: { enable: true, value_area: 1000 } },
                            color: { value: "#ffffff" },
                            shape: {
                                type: "circle",
                                stroke: { width: 0, color: "#000000" },
                                polygon: { nb_sides: 5 },
                                image: { src: "img/github.svg", width: 100, height: 100 }
                            },
                            opacity: {
                                value: 1,
                                random: true,
                                anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
                            },
                            size: {
                                value: 2.5,
                                random: true,
                                anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
                            },
                            move: {
                                enable: true,
                                speed: 0.2,
                                direction: "none",
                                random: true,
                                straight: false,
                                out_mode: "out",
                                bounce: false,
                                attract: { enable: false, rotateX: 600, rotateY: 600 }
                            }
                        },
                        retina_detect: true
                    }}
                />
                <div className='AboutUsSection'>
                    <div className='LandingSectionOuter'>
                        <NavBar />
                        <div style={{ padding: 100 }}>
                            <div className='AboutusTempFlex' >
                                <div className='TempImageHolder' >
                                    <TempLeftImage style={{ width: '400px' }} />
                                </div>
                                <div className='TemptTextHolder' style={{ paddingTop: 150 }}>
                                    <h1 className='LandingPageHOne'>You seem to have searched for a page<span className='LandingPageHOneOrange'></span> that doesn't exsist!</h1>
                                    <span className='LandingPageSpan'>Please try using the links above!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterV2 />
                </div>
            </div >

        </>
    );
};

