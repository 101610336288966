import React, { useEffect, useState } from 'react';

// CSS Files
import './../App.css';
import NavBar from '../components/NavBar';
import { HundredSpacer, TenSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from '../components/Footer';
import PersonalDetails from '../components/PersonalDetails';
import { TransactionHistory } from '../Requests/Account';
import LinearProgress from '@mui/material/LinearProgress';

// Components

// Styled Components

// SVGS

// Icons

// Pages


const AccountPaymentHistory: React.FC = () => {
    const itemsPerPage = 10; // Adjust the number of items per page
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [isLoading, setIsLoading] = useState<any>(true);

    const [paymentHistory, setPaymentHistory] = useState([])
    useEffect(() => {

        retriveTransaction();
    }, []);

    async function retriveTransaction() {
        setIsLoading(true)
        let transData = await TransactionHistory();
        setIsLoading(false)

        if (transData && typeof transData === 'object') {
            setPaymentHistory(transData);
            // console.log(transData);
        }
    }

    const paginatedData = paymentHistory.slice(startIndex, endIndex);



    return (
        <>
            <ThirtySpacer />
            <span className='PersonalDetailsSpan'>Payment History</span>
            <TwenetySpacer />
            <table>
                <thead>
                    <tr>
                        <th className='TableHeaderColumn'><span>Payment ID</span></th>
                        <th className='TableHeaderColumn'><span >Item Name</span></th>
                        <th className='TableHeaderColumn'><span >Payment Status</span></th>
                        <th className='TableHeaderColumn'><span >Amount Gross</span></th>
                        <th className='TableHeaderColumn hiddencolumns'><span >First Name</span></th>
                        <th className='TableHeaderColumn hiddencolumns'><span >Last Name</span></th>
                        <th className='TableHeaderColumn'><span >Email Address</span></th>
                        <th className='TableHeaderColumn'><span >Billing Date</span></th>
                        <th className='TableHeaderColumn hiddencolumns'><span >Created At</span></th>
                    </tr>
                </thead>
                <tbody style={{ height: '50px', overflowY: 'auto' }}>
                    <TenSpacer />
                    {paymentHistory && paymentHistory.length > 0 ? paymentHistory.map((transaction: any) => {

                        return (<>

                            <tr>
                                <td className='TableDivColumn'><span >{transaction.m_payment_id}</span></td>
                                <td className='TableDivColumn'><span >{transaction.item_name}</span></td>
                                <td className='TableDivColumn'><span >{transaction.payment_status}</span></td>
                                <td className='TableDivColumn'><span >{transaction.amount_gross}</span></td>
                                <td className='TableDivColumn hiddencolumns'><span >{transaction.name_first}</span></td>
                                <td className='TableDivColumn hiddencolumns'><span >{transaction.name_last}</span></td>
                                <td className='TableDivColumn'><span >{transaction.email_address}</span></td>
                                <td className='TableDivColumn'><span >{transaction.billing_date}</span></td>
                                <td className='TableDivColumn hiddencolumns'><span >{transaction.created_at}</span></td>
                            </tr>

                        </>)
                    }) :
                        <>{isLoading ? <LinearProgress variant="query" />
                            :
                            <span style={{ display: "block", width: "100%", textAlign: "left", color: "red", }}>
                                <b>You do not own any organisations{/*, to create an organization, please <a onClick={openModal}>click here</a>*/}</b>
                            </span >}</>}
                </tbody>
            </table>
            {/* <span style={{ color: "white" }}>Update your billing details and address</span> */}
            <ThirtySpacer />

        </>
    );
};

export default AccountPaymentHistory;