import React, { useCallback, useEffect, useRef, useState } from 'react';

// CSS Files
import './../App.css';
import '../Particles.css'
import './../AccountVerification.css'
// Components
import LandingSection from '../components/LandingSection';
import OurVaulesSection from '../components/OurValuesSection';
import ServiceAndProducts from '../components/ServicesAndProductsSection';
import AboutOurTeam from '../components/OurClients';
import ContactUs from '../components/ContactUs';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import type { Container, Engine } from "tsparticles-engine";
import { ReactComponent as ForgotPasswordImg } from './../imgs/forgotPasswordImg.svg';
// Styled Components

// SVGS

// Icons

// Pages

export default function ForgotPassword() {
    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // console.log(container);
    }, []);

    return (
        <div className='mainVA accountVerificationPage'>
            <Particles className='onePageParticles'
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}

                options={{
                    fullScreen: true,
                    particles: {
                        number: { value: 120, density: { enable: true, value_area: 1000 } },
                        color: { value: "#ffffff" },
                        shape: {
                            type: "circle",
                            stroke: { width: 0, color: "#000000" },
                            polygon: { nb_sides: 5 },
                            image: { src: "img/github.svg", width: 100, height: 100 }
                        },
                        opacity: {
                            value: 1,
                            random: true,
                            anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
                        },
                        size: {
                            value: 2.5,
                            random: true,
                            anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
                        },
                        move: {
                            enable: true,
                            speed: 0.2,
                            direction: "none",
                            random: true,
                            straight: false,
                            out_mode: "out",
                            bounce: false,
                            attract: { enable: false, rotateX: 600, rotateY: 600 }
                        }
                    },
                    retina_detect: true
                }}
            />
            <ResetingPassword />

        </div >
    );
};

function ResetingPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [status_Text, setStatus_Text] = useState<any>(null);
    const [isError, setIsError] = useState(false);
    const nav = useNavigate();
    const [foundParms, setFoundParms] = useState(true);

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);

    async function sendNewPassword() {
        setIsError(false)
        if (!queryParameters.get("token") || !queryParameters.get("token")) {
            setStatus_Text("URL Corrupted");
            setIsError(true);
            return
        }
        let domain = process.env.REACT_APP_API_URL_FMS;
        let body = {
            confirm_password: confirmPassword,
            password: password
        }
        let endpoint = `${domain}v1/user/passwordreset/${queryParameters.get("id")}/${queryParameters.get("token")}`;
        const response = await axios.put(endpoint, body);
        if (typeof response.data.statusCode !== 'undefined' && response.data.statusCode === 0) {
            setStatus_Text("Successful");
        } else {
            setStatus_Text(response.data.description || "Link Expired");
            setIsError(true)
        }
    }

    useEffect(() => {
        if (!queryParameters.get("token") || !queryParameters.get("token")) {
            setFoundParms(false);
            return
        }
    }, [])

    const handlePasswordChange = (e: any) => {
        setStatus_Text(null)
        setIsError(false)
        setPassword(e.target.value)
    }
    const handleConfirmPasswordChange = (e: any) => {
        setStatus_Text(null)
        setIsError(false)
        setConfirmPassword(e.target.value)
    }




    function handleConfirmPassword() {
        setStatus_Text(null)
        if (password !== confirmPassword) {
            setStatus_Text("Passwords do not match")
        }

        sendNewPassword();
    }
    return (
        <>
            <div className='accountVerificationContainer'>
                <div className='bottomTextContainer'>
                    <div className={`bottomTextWhiteBlock paddingExtra ${status_Text ? `${status_Text === 'Successful' ? "decreaseHeight" : `increaseHeight`}` : ""}`}>

                        {status_Text && !isError ?
                            <>
                                <ForgotPasswordImg style={{ width: 300, position: 'absolute', top: -200, left: 50 }} />
                                <div className='bottomTextGrayBlock'>
                                    <span className='textSuccessfull'>{status_Text}</span>
                                </div>
                                <div>
                                    <button className='returnHomeBTNForgotPassword' onClick={() => { nav('/') }}>Return Home</button>
                                </div>
                            </> :
                            <>
                                {foundParms ? <>
                                    <ForgotPasswordImg style={{ width: 300, position: 'absolute', top: -200, left: 50 }} />
                                    <h1 style={{ margin: 0 }} >Reset Your Password</h1>
                                    <div style={{ paddingTop: 20 }}>

                                        <span >Please fill out your new password information below. </span>
                                        <div style={{ paddingTop: 30, paddingBottom: 10 }}>
                                            {status_Text &&
                                                <>{isError ? <div className='bottomTextGrayBlock'>
                                                    <span className='textError'>{status_Text}</span>
                                                </div> : <div className='bottomTextGrayBlock'>
                                                    <span className='textSuccessfull'>{status_Text}</span>
                                                </div>}
                                                </>
                                            }

                                            {/* <span>Password</span> */}
                                            <input className='inputFieldForgotPassword' type='password' placeholder='Password' value={password} onChange={handlePasswordChange} />
                                            {/* <span>Confirm Password</span> */}
                                            <br />
                                            <input className='inputFieldForgotPassword' type='password' placeholder='Confirm Password' value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                        </div>
                                    </div>


                                    {/* <div style={{ height: "100%", display: 'flex', position: 'relative', bottom: 0, left: 0 }}> */}
                                    <button className='setPassword' onClick={handleConfirmPassword}>Set New Password</button>
                                    {/* </div> */}

                                </> :
                                    <>
                                        <h1 style={{ margin: 0, paddingBottom: 15 }}> Error With The Link</h1>
                                        <div className='bottomTextGrayBlock'>
                                            <span className='textError'>Please Use A Valid Link</span>
                                        </div>
                                        <button className='returnHomeBTNForgotPassword' onClick={() => { nav('/') }}>Return Home</button>
                                    </>
                                }
                            </>}
                    </div>
                </div>
            </div >

        </ >
    );
}
