import React, { useCallback, useEffect, useState } from 'react';

// CSS Files
import './../App.css';
import '../Particles.css'
import NavBar from '../components/NavBar';
import { HundredSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import FooterV2 from '../components/FooterV2';
import PersonalDetails from '../components/PersonalDetails';
import AccountSettings from '../components/AccountSettings';
import AccountSubscription from '../components/AccountSubscriptions';
import AccountPaymentHistory from '../components/AccountPaymentHistory';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import type { Container, Engine } from "tsparticles-engine";
import AccountPassword from '../components/AccountPassword';
import { validateToken } from '../Requests/Account';
import { useNavigate } from 'react-router-dom';
import AccountDownloads from '../components/AccountDownloads';
import FooterV2SinglePage from '../components/FooterV2SinglePage';


// Components

// Styled Components

// SVGS

// Icons

// Pages



const MyAccount: React.FC = () => {
    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // console.log(container);
    }, []);
    return (
        <div className='main'>
            <Particles className='onePageParticles'
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}

                options={{
                    fullScreen: false,
                    particles: {
                        number: { value: 120, density: { enable: true, value_area: 1000 } },
                        color: { value: "#ffffff" },
                        shape: {
                            type: "circle",
                            stroke: { width: 0, color: "#000000" },
                            polygon: { nb_sides: 5 },
                            image: { src: "img/github.svg", width: 100, height: 100 }
                        },
                        opacity: {
                            value: 1,
                            random: true,
                            anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
                        },
                        size: {
                            value: 2.5,
                            random: true,
                            anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
                        },
                        move: {
                            enable: true,
                            speed: 0.2,
                            direction: "none",
                            random: true,
                            straight: false,
                            out_mode: "out",
                            bounce: false,
                            attract: { enable: false, rotateX: 600, rotateY: 600 }
                        }
                    },
                    retina_detect: true
                }}
            />
            <MyAccountLogic />
            <FooterV2SinglePage />
        </div>
    )

};

function MyAccountLogic() {

    const [activeTab, setActiveTab] = useState('Account');
    const nav = useNavigate();

    const handleButtonNavClick = (tabName: any) => {
        setActiveTab(tabName);
    };

    const renderComponent = () => {
        switch (activeTab) {
            case 'Account':
                return <PersonalDetails />;
            // case 'Settings':
            //     return <AccountSettings />;
            case 'Subscriptions':
                return <AccountSubscription />;
            // case 'Payments':
            //     return <PaymentsComponent />;
            case 'PaymentHistory':
                return <AccountPaymentHistory />;
            case 'Password':
                return <AccountPassword />;
            // case 'Tickets':
            //     return <TicketsComponent />;
            case 'Downloads':
                return <AccountDownloads />;
            default:
                return null;
        }
    };
    async function ValidateAuth() {
        // console.log('Validate Auth')
        let vaild = await validateToken()
        if (!vaild) {
            localStorage.removeItem('Auth');
            localStorage.removeItem('AuthR');
            localStorage.removeItem('StayLogin');
            nav('/')
        }
    }

    useEffect(() => {
        ValidateAuth();
        // console.log("validate")
    }, [activeTab]);

    return (
        <>

            <div className='AccountSection'>
                <div className='MyAccountOuter'>
                    <NavBar />
                    <div className='MyAccountInner'>
                        <div>
                            <div className='TabsAccountContainer'>
                                <button className={`TabBtns ${activeTab === "Account" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => { handleButtonNavClick('Account') }}>Account</button>
                                {/* <button className={`TabBtns ${activeTab === "Settings" ? 'TabBtnsSelected' : ''}`}
                                        onClick={() => handleButtonNavClick('Settings')}>Settings</button> */}
                                <button className={`TabBtns ${activeTab === "Subscriptions" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => { handleButtonNavClick('Subscriptions') }}>Subscriptions</button>
                                {/* <button>Payments</button> */}
                                <button className={`TabBtns ${activeTab === "PaymentHistory" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => { handleButtonNavClick('PaymentHistory') }}>Payment History</button>
                                <button className={`TabBtns ${activeTab === "Password" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => handleButtonNavClick('Password')}>Password</button>
                                <button className={`TabBtns ${activeTab === "Downloads" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => handleButtonNavClick('Downloads')}>Downloads</button>
                            </div>
                            <div className='ContentsDiv'>
                                {/* <PersonalDetails /> */}
                                {/* <AccountSettings /> */}
                                {/* <AccountSubscription /> */}
                                {renderComponent()}
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default MyAccount;