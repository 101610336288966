import React, { useCallback, useState } from 'react';

// CSS Files
import './../App.css';

// Components
import { Link, useNavigate } from 'react-router-dom';
import AirlaVault from '../components/AirlaVault';
import NavBar from '../components/NavBar';
import { FiftySpacer, HundredSpacer, ThirtySpacer } from '../styledcomponents/spacers';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import type { Container, Engine } from "tsparticles-engine";
import Revisions from '../components/Revisions';
import ContactUsV2 from '../components/ContactUsV2';
import FooterV2 from '../components/FooterV2';
import WordpressPackages from '../components/WordpressPackages';
import ReactJSPackages from '../components/ReactJSPackages';
import WebDesignPackages from '../components/WebDesignPackages';

// Styled Components

// SVGS

// Icons

// Pages

export default function Packages() {
    const nav = useNavigate();
    const [openAirlaVault, setOpenAirlaVault] = useState(false);
    const [activeTab, setActiveTab] = useState('WordPress');

    const handleButtonNavClick = (tabName: any) => {
        setActiveTab(tabName);
    };

    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // console.log(container);
    }, []);




    const renderComponent = () => {
        switch (activeTab) {
            case 'WordPress':
                return <><WordpressPackages /></>;
            case 'ReactJs':
                return <><ReactJSPackages /></>;
            case 'Web Hosting':
                return <></>;
            // case 'Payments':
            //     return <PaymentsComponent />;
            case 'Web Design':
                return <><WebDesignPackages /></>;
            case 'Revisions':
                return <Revisions />;
            // case 'Tickets':
            //     return <TicketsComponent />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className='OurOfferingsBackground'>
                <div className='OurOfferingsSection'>
                    <div className='PackagesNavSectionOuter'>
                        <NavBar />
                    </div>
                    <div className='PackagesSectionOuter'>
                        {/* <div className='LandingSectionInner'> */}
                        {/* {openAirlaVault && <AirlaVault />} */}
                        {/* <div className='RightFlexDiv'>
                            </div> */}
                        <div className='ServiceOfferingHeadingDiv'>
                            <h1 className='LandingPageHOne'>Our Service <span className='LandingPageHOneOrange'>Offerings</span></h1>
                            <span className='LandingPageSpan'>We stand out for our unwavering commitment to delivering cutting-edge solutions, our proven track record of exceeding expectations, and our relentless pursuit of excellence. Clients can trust us to go above and beyond in every project, ensuring exceptional outcomes and lasting success.</span>
                        </div>

                        {/* </div> */}

                    </div>
                    <HundredSpacer />
                    <div className='PackagesSectionOuter'>
                        <div className='TabsContainer'>
                            <div className='TabsInnerContainer'>
                                <button className={`TabBtns ${activeTab === "WordPress" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => handleButtonNavClick('WordPress')}>
                                    WordPress
                                </button>
                                <button className={`TabBtns ${activeTab === "ReactJs" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => handleButtonNavClick('ReactJs')}>
                                    ReactJs
                                </button>
                                {/* <button className={`TabBtns ${activeTab === "Web Hosting" ? 'TabBtnsSelected' : ''}`}
                                onClick={() => handleButtonNavClick('Web Hosting')}>
                                Web Hosting
                            </button> */}
                                <button className={`TabBtns ${activeTab === "Web Design" ? 'TabBtnsSelected' : ''}`}
                                    onClick={() => handleButtonNavClick('Web Design')}>
                                    Web Design
                                </button>
                            </div>
                            <button className={`TabBtnsRevisions ${activeTab === "Revisions" ? 'TabBtnsRevisionsSelected' : ''}`}
                                onClick={() => handleButtonNavClick('Revisions')}>
                                Revisions
                            </button>
                        </div>
                    </div>
                    <div className='ContentsDiv'>
                        {/* <PersonalDetails /> */}
                        {/* <AccountSettings /> */}
                        {/* <AccountSubscription /> */}
                        {renderComponent()}
                    </div>
                    {/* <span>AirlaVault</span>
                        <button onClick={() => { setOpenAirlaVault(true) }} >Purchase</button> */}
                    <FiftySpacer />
                    <div className='ContactContainer'>
                        <ContactUsV2 />
                    </div>
                    <FooterV2 />
                </div>
            </div >
        </>
    );
};

