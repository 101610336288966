import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as AirlaLogo } from './../imgs/Group 428.svg';
import './../App.css';
import { AiFillLinkedin, AiFillTwitterSquare, AiFillFacebook } from 'react-icons/ai';
import { FifteenSpacer, FiftySpacer, FiveSpacer, HundredSpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { ReactComponent as TempRightImage } from './../imgs/RightImage.svg';
import NavBar from './NavBar';
import Swiper from './Swiper';
import { getProjects } from '../storedData/ourWork';
export default function ServiceAndProducts() {
  const [projects, setProjects] = useState<any[]>([]);
  const nav = useNavigate();
  useEffect(() => {
    const data = getProjects();
    setProjects(data);
  }, []);



  return (
    <>
      <div className='OurValuesContainer' id="serviceAndProductsHeading">
        <div className='midsectionflex'>
          <div>
            <span className='smallHeader'>because every business has unique needs</span>
            <br />
            <h1 className='LandingPageHOne'>Services <span className='LandingPageHOneOrange'>tailored</span> to your business</h1>

            <div className='TextWidth'>
              <span className='LandingPageSpan'>Every business operates in its own unique way, and a one-size-fits-all approach simply doesn't cut it. We understand that your business has specific goals, challenges, and customer expectations. That's why we take the time to get to know your business inside and out, ensuring that every solution we provide is perfectly aligned with your needs. Your website should be as unique as your business. </span>
            </div>
            <TwenetySpacer />
            <TwenetySpacer />
            <div className='ColouredBoxesFlex'>
              {projects.map((project) => (
                <div className='ColouredBoxes'>

                  <div style={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${project.logo})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    height: '40%',
                    widows: '40%',

                  }} key={project.id}>
                  </div>
                  <div className='ColouredTextBox' >
                    <div className='ColouredTextLayout'>
                      <h2 className='LandingPageHOneOrange'>{project.title}</h2>
                      <h3 >Type: {project.tags}</h3>
                      <br />
                      <div className='ColouredTextDescription' >
                        <span >{project.description}</span>
                      </div>
                      <button className='ColouredBoxesButton'
                        onClick={() => {
                          nav('/client', { state: { clientData: project } })
                        }}>
                        See More
                      </button>
                    </div>

                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

