import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as AirlaLogo } from './../imgs/Group 428.svg';
import './../App.css';
import { AiFillLinkedin, AiFillTwitterSquare, AiFillFacebook } from 'react-icons/ai';
import { HundredSpacer, TenSpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import NavBar from '../components/NavBar';

export default function LandingSection() {
  const nav = useNavigate();

  const handleScroll = () => {
    const element = document.getElementById('serviceAndProductsHeading');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <div className='LandingSection '>
        <div className='LandingSectionOuter'>
          <NavBar />
          <div className='LandingSectionInner'>
            <div className='RightFlexDiv '>
              <div className='hiddenLeft'>
                <h1 className='LandingPageHOne'>A dynamic <span className='LandingPageHOneOrange'>development team</span> united by an  insatiable drive for <span className='LandingPageHOneOrange'>achieving excellence</span>.</h1>
                <span className='LandingPageSpan'>We stand out for our unwavering commitment to delivering cutting-edge solutions, our proven track record of
                  exceeding expectations, and our relentless pursuit of excellence. Clients can trust us to go above and beyond in
                  every project, ensuring exceptional outcomes and lasting success</span>
                <TenSpacer />
                <TenSpacer />
                <div className='BtnFlex'>
                  <button className='LandingBtn' onClick={() => { nav("/ourofferings") }}>View Packages</button>
                  <button className='LandingBtn' onClick={handleScroll}>See our Work</button>
                </div>
              </div>
              <div className='SocialBtnsPOS'>
                {/* <div className='BtnFlex'>
                  <AiFillLinkedin className='SocialBtns' />
                  <AiFillTwitterSquare className='SocialBtns' />
                  <AiFillFacebook className='SocialBtns' />
                </div> */}
              </div>
            </div>
            <TempRightImage className='RightImage MediumDisplayNone ImghiddenLeft' />
          </div>
        </div>
      </div>
    </>
  );
};

