import axios from "axios";
import cryptoJS from "crypto-js";
import { jwtDecode } from "jwt-decode";

export async function LoginRequest(data: any) {
    let success = false;
    await axios
        .post(`${process.env.REACT_APP_API_URL_FMS}v1/user/login`, {
            email: data.emailAddress,
            password: data.password,
        })
        .then(function (response: any) {

            localStorage.setItem("Auth", response.data.accessToken);
            localStorage.setItem("AuthR", response.data.refreshToken);
            // localStorage.setItem("StayLogin", stay + "");

            success = true;
            return true;
        })

        .catch((error: Error) => {
            if (error) {

                console.log(error);
                success = false;
                return false;
            }
        });

    return success
}
export async function sendForgotPassword(data: any) {
    let success = false;
    await axios
        .get(`${process.env.REACT_APP_API_URL_FMS}v1/user/forgotpwd/${data.emailAddress}`)
        .then(function (response: any) {
            console.log(response.data)
            if (response.data.statusCode === 0 || response.data.description === "Successful") {
                success = true;
                return true;
            } else {
                success = false;
                return false;
            }
        })

        .catch(function (error: any) {
            if (error) {

                console.log(Error);
                success = false;
                return false;
            }
        });

    return success
}

export async function RegisterRequest(data: any) {
    let success = false;
    await axios
        .post(`${process.env.REACT_APP_API_URL_FMS}v1/user/`, {
            email: data.emailAddress,
            password: data.password,
            first_name: data.firstName,
            last_name: data.lastName,
            confirm_password: data.confirmPassword,
        })
        .then(function (response) {
            success = true;
            return true;
        })
        .catch(function (error) {
            console.log(error);
            success = false;
            return false;
        });

    return success
}



export async function generateSignature(data: any, passPhrase: string = "") {
    // Create parameter string

    data.passphrase = passPhrase
    let pfParamString = "";
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (data.hasOwnProperty(key) && key !== "signature") {
            pfParamString += `${key}=${encodeURIComponent(data[key]).replace(/%20/g, "+")}`;

            // Add '&' if it's not the last key-value pair
            if (i !== keys.length - 1) {
                pfParamString += '&';
            }
        }
    }


    // console.log(pfParamString)
    let md5Data = await cryptoJS.MD5(pfParamString);

    // Conversion WordArray -> Uint8Array
    var binString = md5Data.toString(cryptoJS.enc.Hex);
    return binString;
}

export async function DecodeJWT(data: any) {
    try {

        const decoded = jwtDecode(data);
        return decoded;
    } catch (e: any) {
        console.log(e)
        return false;
    }
}
