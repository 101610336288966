import React, { useEffect, useState } from 'react';

// CSS Files
import './../App.css';
import NavBar from './NavBar';
import { HundredSpacer, TenSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from './Footer';
import PersonalDetails from './PersonalDetails';
import { getDMGDownloadLinkFMS, getEXEDownloadLinkFMS } from '../Requests/Account';

// Components

// Styled Components

// SVGS

// Icons

// Pages




const AccountDownloads: React.FC = () => {
    const [exeDownloadLink, setExeDownloadLink] = useState(null);
    const [dmgDownloadLink, setDmgDownloadLink] = useState(null);

    async function downloadLink() {
        let exeD: any = await getEXEDownloadLinkFMS();
        let dmgD: any = await getDMGDownloadLinkFMS();
        // console.log(exeD);
        if (exeD) {
            setExeDownloadLink(exeD)
        }
        if (dmgD) {
            setDmgDownloadLink(dmgD)
        }

    }

    useEffect(() => {
        downloadLink();
    }, []);

    return (
        <>
            <ThirtySpacer />
            <span className='PersonalDetailsSpan'>Download Links:</span>
            <TenSpacer />
            <span className='DownloadText'>Below are the different operating versions of Airvidence Application.
                <br />
                To download them, Please simply click the respective buttons
            </span>
            <ThirtySpacer />
            <div className='DownloadFlex'>
                <div>
                    <span className='PersonalDetailsSpan'>Windows Operating System:</span>
                    <TenSpacer />
                    <span className='DownloadText'>To begin downloading the Windows installer for Airvidence, <br/>click the button below:</span>
                    <TenSpacer />
                    {exeDownloadLink ? <a className='downloadbutton' href={exeDownloadLink}>Download Airvidence.exe</a> : <button disabled>Download Airvidence.exe</button>}
                </div>
                <div>
                    <span className='PersonalDetailsSpan'>MacOS:</span>
                    <TenSpacer />
                    <span className='DownloadText'>To begin downloading the MacOS installer for Airvidence, <br/>click the button below:</span>
                    <TenSpacer />
                    {dmgDownloadLink ? <a className='downloadbutton' href={dmgDownloadLink}>Download Airvidence.dmg</a> : <button disabled>Download Airvidence.dmg</button>}
                </div>
            </div>

        </>
    );
};

export default AccountDownloads;