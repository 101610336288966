import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AirlaFooter } from './../imgs/FootericonAni.svg';
import './../App.css';
import { AiFillLinkedin, AiFillTwitterSquare, AiFillFacebook } from 'react-icons/ai';
import { FiveSpacer, TenSpacer } from '../styledcomponents/spacers';
export default function FooterV2() { //test
  const menuData = [
    {
      category: 'Quick Links',
      links: [

        { label: 'Airvidence Packages', url: '/ourofferings' },
        { label: 'Enquire', url: '/contactus' },
        { label: 'The Forge', url: '/aboutus' },
        { label: 'Login', url: '/onboarding' }
      ]
    },
    // Add more categories as needed
  ];

  const menuData2 = [
    {
      category: 'Services',
      links: [
        { label: 'Packages', url: '/ourofferings' },
        { label: 'Web Development', url: '/ourofferings' },
        { label: 'Web Hosting', url: '/ourofferings' }
      ]
    },
    // Add more categories as needed
  ];

  const menuData3 = [
    {
      category: 'Social',
      links: [
        { label: 'Linkedin', url: '' },
        { label: 'Instagram', url: '' },
        { label: 'Twitter', url: '' }
      ]
    },
    // Add more categories as needed
  ];

  return (
    <>
      <div className='NewFooterContainerSinglePage'>
        <div className='BottomFooterSection'>
            <div className='BottomTextFlex'>
              <a className='SubtextFooterSpanCopyright'>© 2024 AirlaForge</a>
              <a className='SubtextFooterSpan'>Support</a>
              <a className='SubtextFooterSpan'>Privacy Policy</a>
              <a className='SubtextFooterSpan'>Terms of Use</a>
              <a className='SubtextFooterSpan'>Cookie Policy</a>
            </div>
        </div>
      </div>
    </>
  );
};

