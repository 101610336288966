import React, { useCallback, useEffect, useState } from 'react';

// CSS Files
import './../App.css';
import './Styling/homePage.css';
import './../Particles.css'

// Components
import LandingSection from '../components/LandingSection';
import OurVaulesSection from '../components/OurValuesSection';
import ServiceAndProducts from '../components/ServicesAndProductsSection';
import OurClients from '../components/OurClients';
import OurPortfolio from '../components/OurPortfoilio';
import ContactUs from '../components/ContactUs';

// Styled Components

// SVGS

// Icons
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim";
import InquiryModal from '../components/Modals/InquiryModal';
import FooterV2 from '../components/FooterV2';
// Pages

const Home: React.FC = () => {


  const particlesInit = useCallback(async (engine: Engine) => {
    // console.log(engine);

    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    // console.log(container);
  }, []);

  useEffect(() => {
    const observerLeft = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showLeft")
        } else {
          entry.target.classList.remove("showLeft")
        }
      })
    })

    const hiddenElementsLeft = document.querySelectorAll(".hiddenLeft")
    hiddenElementsLeft.forEach((el) => observerLeft.observe(el))

    const observerBot = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBot")
        } else {
          entry.target.classList.remove("showBot")
        }
      })
    })

    const hiddenElementsBot = document.querySelectorAll(".hiddenBot")
    hiddenElementsBot.forEach((el) => observerBot.observe(el))


  }, [])

  // New Aninmation Nav

  useEffect(() => {
    const observerLeft = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("NavshowLeft")
        } else {
          entry.target.classList.remove("NavshowLeft")
        }
      })
    })

    const hiddenElementsLeft = document.querySelectorAll(".NavhiddenLeft")
    hiddenElementsLeft.forEach((el) => observerLeft.observe(el))

    const observerBot = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("NavshowBot")
        } else {
          entry.target.classList.remove("NavshowBot")
        }
      })
    })

    const hiddenElementsBot = document.querySelectorAll(".NavhiddenBot")
    hiddenElementsBot.forEach((el) => observerBot.observe(el))


  }, [])

  // New Aninmation Right Image

  useEffect(() => {
    const observerLeft = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("ImgshowLeft")
        } else {
          entry.target.classList.remove("ImgshowLeft")
        }
      })
    })

    const hiddenElementsLeft = document.querySelectorAll(".ImghiddenLeft")
    hiddenElementsLeft.forEach((el) => observerLeft.observe(el))

    const observerBot = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("ImgshowBot")
        } else {
          entry.target.classList.remove("ImgshowBot")
        }
      })
    })

    const hiddenElementsBot = document.querySelectorAll(".ImghiddenBot")
    hiddenElementsBot.forEach((el) => observerBot.observe(el))


  }, [])

  return (
    <>
      {/*Body with footer */}
      <Particles className='mainPageParticles'
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: false,
          particles: {
            number: { value: 120, density: { enable: true, value_area: 1000 } },
            color: { value: "#ffffff" },
            shape: {
              type: "circle",
              stroke: { width: 0, color: "#000000" },
              polygon: { nb_sides: 5 },
              image: { src: "img/github.svg", width: 100, height: 100 }
            },
            opacity: {
              value: 1,
              random: true,
              anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
            },
            size: {
              value: 2.5,
              random: true,
              anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
            },
            // line_linked: {
            //     enable: true,
            //     distance: 30,
            //     color: "#ffffff",
            //     opacity: 0.4,
            //     width: 1
            // },
            move: {
              enable: true,
              speed: 0.2,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 600 }
            }
          },
          retina_detect: true
        }}
      />
      <div className="main">
        <LandingSection />
        <OurVaulesSection />
        {/* <OurPortfolio/> */}
        <ServiceAndProducts />
        <ContactUs />
        <OurClients />
        <FooterV2 />
      </div >

    </>
  );
};

export default Home;