import axios from "axios";

export async function getPackages() { // sends logs to all bbc members

    let success: any = false;

    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/payments/packages`
    let options = {
        headers: {
            ContentType: "application/json"
        }
    };
    try {
        let response = await axios.get(endpoint, options)
        // console.log(response.data);
        if (typeof response.data.packages !== "undefined") {
            console.log(response.data)
            return response.data.packages;
        } else {
            return false;
        }
    } catch (err) {

        return false;

    }



}