import axios from "axios";
import { jwtDecode } from "jwt-decode";


export async function TransactionHistory() {
    let success = false;


    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/payments/history`

    let token = localStorage.getItem("Auth");
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };
    await axios.get(endpoint, options)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.statuscode !== "undefined") {

                success = response.data.transactions;
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success
}

export async function UserDetails() {
    let success = false;


    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/user/`

    let token = localStorage.getItem("Auth");
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };
    await axios.get(endpoint, options)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.statusCode !== "undefined") {

                success = response.data.user;
                return success
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success
}

export async function putUserDetails(userData: any) {
    let success = false;


    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/user/`

    let token = localStorage.getItem("Auth");
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };
    let body = {
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone_main: userData.phone_main
    };
    await axios.put(endpoint, body, options,)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.statusCode !== "undefined") {


                return true
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success
}

export async function updatePassword(passwordData: any) {
    let success = false;

    let body = {
        current_password: passwordData.currentPassword,
        confirm_password: passwordData.confirmPassword,
        password: passwordData.newPassword
    }
    let token = localStorage.getItem("Auth");

    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/user/password`
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };

    await axios.put(endpoint, body, options)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.statusCode !== "undefined" && (response.data.statusCode === 0 || response.data.statusCode === "0")) {
                success = true;
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success

}
export async function getforgotPassword(email: string) {
    let success = false;


    // let token = localStorage.getItem("Auth");

    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/user/forgotpwd/${email}`
    // let options = {
    //     headers: {
    //         ContentType: "application/json",
    //         Authorization: `Bearer ${token}`
    //     }
    // };

    await axios.get(endpoint)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.statusCode !== "undefined" && (response.data.statusCode === 0 || response.data.statusCode === "0")) {
                success = true;
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success

}

export async function putCancelSubscriptionFMS(transtoken: string) {
    let success = false;


    let token = localStorage.getItem("Auth");

    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/payments/cancel/${transtoken}`
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };

    await axios.put(endpoint, {}, options)
        .then(response => {
            // console.log(response.data);
            success = true;
            // if (typeof response.data.statusCode !== "undefined" && (response.data.statusCode === 0 || response.data.statusCode === "0")) {
            //     success = true;
            // }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success

}


export async function validateToken() {
    try {
        // Decode the token (without verifying the signature)
        let token = localStorage.getItem('Auth');

        if (typeof token === "string") {
            const decodedToken: any = jwtDecode(token);
            if (decodedToken && decodedToken.exp) {
                const expirationTime = decodedToken.exp;
                const currentTime = Math.floor(Date.now() / 1000);
                // console.log(decodedToken.exp)
                if (currentTime > expirationTime) {
                    //has expired
                    // console.log("Expired")
                    let newToken = await getAccessToken()
                    if (newToken) {
                        // console.log("Got New Token")
                        return true;

                    }
                    // console.log("Failed New Login Req")
                    return false;
                } else {
                    // console.log("All good")
                    return true;
                }
            } else {
                // console.log("JWT ERROR")
                return false;
            }
        }
        // console.log("JWT ERROR")
        return false;
    } catch (error) {
        // Handle decoding errors
        console.error('Error decoding token:', error);
        return false;
    }
};

export async function getAccessToken() {

    let varToken = localStorage.getItem('AuthR')
    // console.log("Getting New Token")
    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL_FMS}v1/token`, { token: varToken }, {
            headers: {
                Authorization: 'Bearer ' + varToken
            }
        })

        if (res.data.accessToken) {
            localStorage.setItem('Auth', res.data.accessToken)
            // console.log("Updating New Token")
            window.location.reload();
            return true;
        } else {
            if (res.status === 403) {

            }

            return false;
        }
    } catch (error) {
        return false;

    }



}


export async function getEXEDownloadLinkFMS() {
    let success = false;


    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/media/getDownloadLink/exe`

    let token = localStorage.getItem("Auth");
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };

    await axios.get(endpoint, options)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.downloadLink !== "undefined") {
                success = response.data.downloadLink
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success
}

export async function getDMGDownloadLinkFMS() {
    let success = false;


    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/media/getDownloadLink/dmg`

    let token = localStorage.getItem("Auth");
    let options = {
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        }
    };

    await axios.get(endpoint, options)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.downloadLink !== "undefined") {
                success = response.data.downloadLink
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success
}