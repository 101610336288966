import React, { useState } from 'react';

import './../App.css';

// Icons
import { FiftySpacer, FiveSpacer, HundredSpacer, TenSpacer, TwenetySpacer } from '../styledcomponents/spacers';
import Footer from './Footer';
import { constactUsEmail } from '../Requests/Emailer';

export default function ContactUsV2() {
    const [requestSuc, setRequestSuc] = useState<any>(null)

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        cellPhone: '',
        emailAddress: '',
        additionalMessages: '',
        subscribeNewsletter: false
    });

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // console.log('Form submitted:', formData);
        // console.log('Form submitted:', formData);

        let body = '\nFirstName: ' + formData.firstName + ' LastName: ' + formData.lastName + '\nCell: ' + formData.cellPhone + '\nMessage: ' + formData.additionalMessages
        // console.log(body);
        let results = await constactUsEmail("ContactUs OurOfferingPage", body, formData.emailAddress)

        if (results) {
            setRequestSuc(true)
        } else {
            setRequestSuc(false)
        }

    };

    return (
        <>

            <div className='PackagesContact'>
                <div className='ValuesSectionInner'>
                    <div className='TextcenterFlex'>
                        <h1 className='LandingPageHOne'>Get in<span className='LandingPageHOneOrange'> touch</span> with us!</h1>
                    </div>
                    <span className='ContactUsTextBtn'>Have any questions? Send us your email and we will get in touch!</span>
                    <TwenetySpacer />
                    <div className='containerdiv'>
                        <div className='formdiv'>
                            <div>
                                {/* <label htmlFor="firstName">First Name:</label> */}
                                <input
                                    type="text"
                                    className='ContactInput'
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    placeholder='First Name'
                                />
                                <FiveSpacer />
                                {/* <label htmlFor="lastName">Last Name:</label> */}
                                <input
                                    type="text"
                                    className='ContactInput'
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    placeholder='Last Name'
                                />
                                <FiveSpacer />
                                <div className='inputflex'>
                                    {/* <label htmlFor="cellPhone">Cell Phone:</label> */}
                                    <input
                                        type="text"
                                        className='ContactInput'
                                        id="cellPhone"
                                        name="cellPhone"
                                        value={formData.cellPhone}
                                        onChange={handleChange}
                                        placeholder='Cell Number'
                                    />
                                    {/* <label htmlFor="emailAddress">Email Address:</label> */}
                                    <input
                                        type="email"
                                        className='ContactInput'
                                        id="emailAddress"
                                        name="emailAddress"
                                        value={formData.emailAddress}
                                        onChange={handleChange}
                                        placeholder='Email Address'
                                    />
                                </div>
                                <FiveSpacer />
                                {/* <label htmlFor="additionalMessages">Additional Messages:</label> */}
                                <textarea
                                    className='ContactInput'
                                    id="additionalMessages"
                                    name="additionalMessages"
                                    value={formData.additionalMessages}
                                    onChange={handleChange}
                                    placeholder='Additional Messages'
                                />
                                <div className='checkboxflex'>
                                    <input
                                        className='checkbox'
                                        type="checkbox"
                                        id="subscribeNewsletter"
                                        name="subscribeNewsletter"
                                        checked={formData.subscribeNewsletter}
                                        onChange={handleChange}
                                    />
                                    <label className='checkboxLabel' htmlFor="subscribeNewsletter">Subscribe to newsletters</label>
                                </div>
                                <TenSpacer />
                                <button className='ContactBtn' type="submit" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

