let data =
    [
        {
            id: 1,
            title: "Casco",
            description: "Under construction...",
            tags: "Client",
            images: [
                "https://res.cloudinary.com/dsjbzd29r/image/upload/v1725007712/Account_2_rhpzmf.png",
                "https://res.cloudinary.com/dsjbzd29r/image/upload/v1725007711/Account_evmgml.png",
                "https://res.cloudinary.com/dsjbzd29r/image/upload/v1725007711/Account_1_ufcv30.png",
                "https://res.cloudinary.com/dsjbzd29r/image/upload/v1725007712/Account_2_rhpzmf.png",
                "https://res.cloudinary.com/dsjbzd29r/image/upload/v1725007711/Account_evmgml.png",
                "https://res.cloudinary.com/dsjbzd29r/image/upload/v1725007711/Account_1_ufcv30.png"
            ],
            logo: "https://res.cloudinary.com/dsjbzd29r/image/upload/v1725008976/Casco_Logo_White_rk8exx.png",
            website_link: null
        }

    ]

export function getProjects() {
    return data

}