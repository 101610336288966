import styled from 'styled-components';

export const OneSpacer = styled.div`
height: 1px;
width: 1px;
`;

export const TwoSpacer = styled.div`
height: 2px;
width: 2px;
`;

export const ThreeSpacer = styled.div`
height: 3px;
width: 3px;
`;

export const FourSpacer = styled.div`
height: 4px;
width: 4px;
`;

export const FiveSpacer = styled.div`
height: 5px;
width: 5px;
`;

export const TenSpacer = styled.div`
height: 10px;
width: 10px;
`;

export const FifteenSpacer = styled.div`
height: 15px;
width: 15px;
`;

export const TwenetySpacer = styled.div`
height: 20px;
width: 20px;
`;

export const ThirtySpacer = styled.div`
height: 30px;
width: 30px;
`;

export const FiftySpacer = styled.div`
height:50px;
width: 50px;
`;

export const HundredSpacer = styled.div`
height: 100px;
width: 100px;
`;