import React, { useEffect, useState } from 'react';

import './../App.css';
import FooterV2 from '../components/FooterV2';

import { getProjects } from '../storedData/ourWork';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
export default function ServiceAndProducts() {
    const [project, setProject] = useState<any>(null);
    const [images, setImages] = useState<any>(null);
    const location = useLocation();
    const nav = useNavigate();
    useEffect(() => {
        if (!location.state || !location.state.clientData) {
            nav('/')
        }

        setProject(location.state.clientData);
        let tempData = []
        for (let i = 0; i < location.state.clientData.images.length; i++) {
            tempData.push({
                original: location.state.clientData.images[i],
                thumbnail: location.state.clientData.images[i],
                thumbnailHeight: 100,
                thumbnailWidth: 250,
                originalHeight: 500,
                originalWidth: 1000,
            })
        }
        setImages(tempData);
        console.log(tempData)
    }, []);

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <>
            <div className='main'>
                {location.state && project &&
                    <div style={{ height: "100vh" }}>
                        <div className='MyAccountOuter'>
                            <NavBar />
                            <div style={{ paddingTop: '40px', paddingBottom: '20px', paddingLeft: "20px" }}>

                                <button className='ColouredBoxesButton' style={{ width: '100px' }}
                                    onClick={() => { nav('/') }}>back</button>
                            </div>
                            <div style={{ width: "100%", flexDirection: "row", display: 'flex' }}>
                                <div style={{ width: "100%", justifyContent: 'center', alignContent: 'center', padding: "20px" }}>

                                    {images &&
                                        <ImageGallery items={images}
                                            showFullscreenButton={false}
                                            thumbnailPosition={'left'}
                                            useBrowserFullscreen={false}
                                            disableThumbnailScroll={true}
                                            showPlayButton={false}
                                        />
                                    }

                                </div>
                                <div style={{
                                    width: "100%",
                                    height: '100%',
                                    background: "linear-gradient(0.1turn, #f8f8f8, #d8d8d8)",
                                    borderRadius: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: "20px",
                                    boxShadow: "5px 10px 20px #22222296",
                                    marginTop: "20px"
                                }}>
                                    <h1 className='LandingPageHOneOrange'>{project.title}</h1>
                                    <h2 className='LandingPageSpan' style={{ color: 'black' }}>Type: {project.tags}</h2>

                                    <h2 className='LandingPageSpan' style={{ color: 'black' }}>Description:<br /></h2>
                                    <span className='LandingPageSpan' style={{ color: 'black' }}>{project.description}</span>

                                    {project.website_link &&
                                        <div style={{ justifyContent: "center", display: 'flex', width: '100%', paddingTop: '20px', paddingBottom: '20px' }}>
                                            <button
                                                className='ColouredBoxesButton'
                                                onClick={() => { openInNewTab(project.website_link) }}>
                                                Website
                                            </button>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                        <FooterV2 />
                    </div>
                }
            </div >
        </>
    );
};

