import axios from 'axios';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import '../App.css'
import '../Particles.css'
// import { BlueFilledButtonRounded } from '../styledcomponents/buttonTypes';
import { FiveSpacer } from '../styledcomponents/spacers';
import NavBar from '../components/NavBar';
import FooterV2 from '../components/FooterV2';
import { ReactComponent as TempLeftImage } from './../imgs/CarCrash.svg';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }


    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can log the error to an error reporting service like Rollbar here
        console.log('Error caught by ErrorBoundary:', error,)
        console.log('Error caught by ErrorBoundary:', errorInfo);
        let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/errorcaught`;
        let token = localStorage.getItem("Auth");
        let body = {
            "errorInfo": errorInfo,
            "error": error.toString(),
            "userToken": token
        }
        let options = {
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${token}`
            }
        };
        try {
            if (process.env.MODE === "prod") {

                const response = axios.post(endpoint, body, options);
            }

        } catch (e: any) {
            // if (e.response.status === 403) {
            //     // let gotToken = await getAccessToken();
            //     if (await getAccessToken()) {
            //         GetFiles();
            //     }

            // }
        }
        this.setState({ hasError: true });
    }

    render() {

        if (this.state.hasError) {
            return <>
                <div className='mainVA' >

                    <div className='AboutUsSection' >
                        <div className='LandingSectionOuter' >
                            <div style={{ display: "flex", justifyContent: 'center', alignContent: 'center', height: "100%" }}>
                                <div className='AboutusTempFlex ' style={{ backgroundColor: 'white', borderRadius: 10, padding: 30, height: '70vh', width: "70vw", justifyContent: 'center', display: 'flex', alignContent: 'center', textAlign: 'center' }} >
                                    {/* <div className='TempImageHolder' > */}
                                    <TempLeftImage style={{ width: '600px' }} />
                                    {/* </div> */}
                                    <div className='TemptTextHolder' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', textAlign: 'center' }}>
                                        <h1 className='LandingPageHOne' style={{ color: 'black', padding: 50 }}>Something went wrong!</h1>
                                        <span className='LandingPageSpan' style={{ color: 'black' }}>Please refresh the webpage.<br /> If the error persists, please contact our support staff</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
