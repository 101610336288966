import React, { useState } from 'react';

// CSS Files
import './../App.css';
import NavBar from '../components/NavBar';
import { FiveSpacer, HundredSpacer, TenSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from '../components/Footer';
import PersonalDetails from '../components/PersonalDetails';
import { getforgotPassword, updatePassword } from '../Requests/Account';

// Components

// Styled Components

// SVGS

// Icons

// Pages

const AccountPassword: React.FC = () => {
    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmPassword: '',
        currentPassword: ''
    });
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const [forgotPassword, setforgotPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [failedVerify, setFailedVerify] = useState(false);
    const [errorText, setErrorText] = useState("Something went wrong.");
    const [successText, setSuccessText] = useState("");
    function validateForm() {
        return email.length > 0;
    }

    const handlePasswordChange = (field: string, value: string) => {
        setPasswords((prevPasswords) => ({
            ...prevPasswords,
            [field]: value,
        }));
        setError('');
        setMessage('');

    };

    const handleSubmit = async () => {
        if (passwords.newPassword !== passwords.confirmPassword) {
            setError('Your new password does not match');
            setMessage('')
            setPasswords({
                ...passwords,
                newPassword: '',
                confirmPassword: '',
                currentPassword: ''
            });
        } else {
            if (passwords.newPassword.length < 4) {
                setError('Password Must Be Longer Than 6 Characters');
                return false
            }
            let results = await updatePassword(passwords);
            // console.log(results);
            if (results) {
                setPasswords({
                    ...passwords,
                    newPassword: '',
                    confirmPassword: '',
                    currentPassword: ''
                });
                setMessage("Passwords updated successfully")
            } else {
                setError('Incorrect Password');
                setMessage('')
                return false;
            }
        }
    };

    async function sendEmail(email: string) {
        let result = await getforgotPassword(email)
        // console.log(result);
        if (result) {
            setFailedVerify(false);
            setSuccessText(
                "Your password reset email has been sent, Please check your inbox"
            );
        } else {
            setFailedVerify(true);
            setErrorText("Something went wrong");
        }
    }

    return (
        <>
            <ThirtySpacer />
            <div className="SpaceBetweenFlex">
                <div>
                    <span className='PersonalDetailsSpan'>Password Settings</span>
                    <br />
                    <span className='PersonalDetailsSpanBody'>Update your password.</span>
                </div>
                <button className='EditDetailsButton' onClick={handleSubmit}>Save</button>
            </div>
            <ThirtySpacer />
            <input
                className='PersonalDetailsInput'
                type="password"
                placeholder='Old Password'
                onChange={(e) => handlePasswordChange('currentPassword', e.target.value)} />
            <FiveSpacer />
            <input
                className='PersonalDetailsInput'
                type="password"
                placeholder='New Password'
                onChange={(e) => handlePasswordChange('newPassword', e.target.value)} />
            <FiveSpacer />
            <input
                className='PersonalDetailsInput'
                type="password"
                placeholder='Confirm New Password'
                onChange={(e) => handlePasswordChange('confirmPassword', e.target.value)} />
            {error && <>
                <div style={{ color: 'red', font: 'Nunito, sans-serif', fontSize: '14px' }}>{error}</div>
                <TenSpacer />
            </>}
            {message && <>
                <div style={{ color: 'green', font: 'Nunito, sans-serif', fontSize: '14px' }}>{message}</div>
                <TenSpacer />
            </>}
            <TenSpacer />
            <button className='forgotyourpasswordBtn' onClick={() => setforgotPassword(true)}>Forgot your password?</button>
            {forgotPassword &&
                <>
                    <TenSpacer />
                    <input
                        className='PersonalDetailsInput'
                        placeholder="Email Address"
                        onChange={(e: any) => setEmail(e.target.value)}
                    />
                    <br />
                    {validateForm() ? (
                        <button className='PasswordResetButton'
                            onClick={() => {
                                sendEmail(email);
                            }}
                        >
                            Submit
                        </button>
                    ) : (
                        <button className='PasswordResetButtonDisabled' disabled>Submit</button>
                    )}
                    {failedVerify && <span style={{ color: 'red' }}>{errorText}</span>}
                    {successText && !failedVerify && (
                        <span style={{ color: 'green' }}>{successText}</span>
                    )}
                </>
            }

        </>
    );
};

export default AccountPassword;