import React, { useState, useEffect } from 'react';

import './../App.css';

// Icons
import { AiOutlineMobile, AiOutlineDesktop, AiOutlineEdit, AiOutlineHistory, AiOutlineLock, AiOutlineSearch, AiOutlineStock, AiOutlineCode } from 'react-icons/ai';
import { FiftySpacer, FiveSpacer, HundredSpacer, TenSpacer, TwenetySpacer } from '../styledcomponents/spacers';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation'; // Import Swiper styles
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';


export default function OurVaulesSection() {


  return (
    <>
      <div className='OurValuesContainer'>
        <div className='CenterTextDiv'>
          <h1 className='LandingPageHOne'>Who <span className='LandingPageHOneOrange'> we</span> are.</h1>
        </div>

        <div className='CenterSubTextDiv'>
          <span className='LandingPageSpan'>Web and software development are the lifeblood of our company. We believe they're vital <br />because they enable businesses to stay competitive, reach wider audiences, and provide better user experiences. Our focus is on creating innovative solutions that drive success in today's digital world.</span>
        </div>
        <TwenetySpacer />
        <TwenetySpacer />
        <TwenetySpacer />
        <div className='BoxesFlex'>
          <div className='WhoWeAreBoxes'>
            <span className='BoxHeader'>Software Development</span>
            <TenSpacer />
            <FiveSpacer />
            <span className='BoxText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
            <div className='IconPos'>
              <AiOutlineCode className="WhoweAreIcon" />
            </div>
          </div>
          <div className='WhoWeAreBoxes'>
            <span className='BoxHeader'>Web Design and Development</span>
            <TenSpacer />
            <FiveSpacer />
            <span className='BoxText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
            <div className='IconPos'>
              <AiOutlineCode className="WhoweAreIcon" />
            </div>
          </div>
        </div>
        <TwenetySpacer />
        <TwenetySpacer />
        <TwenetySpacer />
        <div className='BoxesFlex'>
          <div className='WhoWeAreBoxes'>
            <span className='BoxHeader'>App Design and Development</span>
            <TenSpacer />
            <FiveSpacer />
            <span className='BoxText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
            <div className='IconPos'>
              <AiOutlineCode className="WhoweAreIcon" />
            </div>
          </div>
          <div className='WhoWeAreBoxes'>
            <span className='BoxHeader'>Hosting Services </span>
            <TenSpacer />
            <FiveSpacer />
            <span className='BoxText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
            <div className='IconPos'>
              <AiOutlineCode className="WhoweAreIcon" />
            </div>
          </div>
        </div>
        <TwenetySpacer />
        <TwenetySpacer />
        <TwenetySpacer />
        {/* <div className='CenterDivButton'>
          <button className='DiscoverMoreBtn'>Discover More</button>
        </div> */}
      </div>

    </>
  );
};

