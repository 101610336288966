import axios from "axios";

export async function constactUsEmail(subject: string, data: any, email: string) { // sends logs to all bbc members

    let success = false;

    let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/user/contactus/${email}`
    let options = {
        headers: {
            ContentType: "application/json"
        }
    };

    let body = {
        subject: subject,
        textMessage: data
    };

    await axios.post(endpoint, body, options)
        .then(response => {
            // console.log(response.data);
            if (typeof response.data.statusCode !== "undefined" && (response.data.statusCode === 0 || response.data.statusCode === "0")) {
                success = true;
            }
        })
        .catch(error => {
            // console.log(error.data);
            success = false;
        });

    return success

}