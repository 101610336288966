// Modal.tsx
import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { FiveSpacer, TenSpacer, TwenetySpacer } from '../../styledcomponents/spacers';

// Icons
import { AiFillCloseCircle } from "react-icons/ai";
import { constactUsEmail } from '../../Requests/Emailer';
import type { Container, Engine } from "tsparticles-engine";
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  productText: string; // Add this line to include children
}

const CustomModal: React.FC<ModalProps> = ({ isOpen, onClose, children, productText }) => {
  const [requestSuc, setRequestSuc] = useState<any>(null)
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [formData, setFormData] = useState({
    emailAddress: '',
    additionalMessages: '',
    firstName: '',
    lastName: '',
    cellPhone: ''
  });

  const handleChange = (e: any) => {
    setRequestSuc(null)
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    // Add a class to the body when the modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'visible';
      // document.body.style.overflow = 'hidden';
      // document.body.style.overflow = 'visible';
    }

    // Cleanup function to remove the added class when the component unmounts
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);


  async function submitEmail() {
    let body = '\nFirstName: ' + formData.firstName + ' LastName: ' + formData.lastName + '\nCell: ' + formData.cellPhone + '\nMessage: ' + formData.additionalMessages
    // console.log(body);
    let results = await constactUsEmail(productText, body, formData.emailAddress)

    if (results) {
      setRequestSuc(true)
    } else {
      setRequestSuc(false)
    }
  }

  const particlesInit = useCallback(async (engine: Engine) => {
    // console.log(engine);

    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    // console.log(container);
  }, []);

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      className="InquiryModal"
      overlayClassName="Overlay"
    >
      <AiFillCloseCircle className='CloseButton' onClick={onClose}></AiFillCloseCircle>
      {children}
      <div className="InquiryPaddingContainer">
        <div className="ContactTextContainer">
          <h1 className='InquiryHOne'>We Transform Ideas into<span className='LandingPageHOneOrange'> Digital Marvels</span>.</h1>
          <span className='InquirySpan'>Interested in one of our services or products? Get in touch with us to find out more!</span>
        </div>
        <TwenetySpacer />
        <div className="InquiryFlex">
          <div className='InputsDiv'>
            {/* <label htmlFor="firstName">First Name:</label> */}
            <input
              type="text"
              className='InquiryInput'
              id="firstName"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              placeholder='First Name'
            />
            <FiveSpacer />
            {/* <label htmlFor="lastName">Last Name:</label> */}
            <input
              type="text"
              className='InquiryInput'
              id="lastName"
              name="lastName"
              onChange={handleChange}
              value={formData.lastName}
              placeholder='Last Name'
            />
            <FiveSpacer />
            <div className='inputflex'>
              {/* <label htmlFor="cellPhone">Cell Phone:</label> */}
              <input
                type="text"
                className='InquiryInput'
                id="cellPhone"
                name="cellPhone"
                onChange={handleChange}
                placeholder='Cell Number'
              />
              {/* <label htmlFor="emailAddress">Email Address:</label> */}
              <input
                type="email"
                className='InquiryInput'
                id="emailAddress"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                placeholder='Email Address'
              />
            </div>
            <FiveSpacer />
            <select id="dropdown" className='InquiryDropwdownInput' value={selectedOption} onChange={handleDropdownChange}>
              <option className='InquiryOptions' value="">Please select your Inquiry type</option>
              <option className='InquiryOptions' value="option1">AirlaVault</option>
              <option className='InquiryOptions' value="option2">Wordpress Development</option>
              <option className='InquiryOptions' value="option3">ReactJs Development</option>
              <option className='InquiryOptions' value="option3">Web Design</option>
            </select>
            {/* <label htmlFor="additionalMessages">Additional Messages:</label> */}
            {/* <textarea
            className='InquiryInput'
            id="additionalMessages"
            name="additionalMessages"
            value={formData.additionalMessages}
            onChange={handleChange}
            placeholder='Additional Messages'
          /> */}
            {/* <div className='checkboxflex'>
                  <input
                      className='checkbox'
                      type="checkbox"
                      id="subscribeNewsletter"
                      name="subscribeNewsletter"
                  />
                  <label className='checkboxLabel' htmlFor="subscribeNewsletter">Subscribe to newsletters</label>
              </div> */}
            <TenSpacer />
            <button className='ContactBtn' type="submit" onClick={submitEmail}>Submit</button>
            {requestSuc && <span>Email Sent. You will hear back from us soon.</span>}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
