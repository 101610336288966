import React, { useEffect, useState } from 'react';

// CSS Files
import './../App.css';
import NavBar from '../components/NavBar';
import { FiveSpacer, HundredSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from '../components/Footer';
import { UserDetails, putUserDetails } from '../Requests/Account';

// Components

// Styled Components

// SVGS

// Icons

// Pages

const PersonalDetails: React.FC = () => {
    const [userData, setUserData] = useState<any>({})
    const [userDataEditMode, setUserDataEditMode] = useState<any>(false)

    useEffect(() => {
        getUserDetails()

    }, [])

    const handleInputChange = (key: string, value: any) => {
        setUserData((prevUserData: any) => ({
            ...prevUserData,
            [key]: value,
        }));
    };


    async function getUserDetails() {
        let tempdata = await UserDetails()
        if (tempdata) {
            // console.log(tempdata)
            setUserData(tempdata);
        }
    }
    async function changeUserDetails() {
        putUserDetails(userData);
    }
    return (
        <>
            <ThirtySpacer />
            <div className="SpaceBetweenFlex">
                <span className='PersonalDetailsSpan'>Personal Details</span>
                {userDataEditMode ? <button className='EditDetailsButton' onClick={() => setUserDataEditMode(false)}>Save Details</button> :
                    <button className='EditDetailsButton' onClick={() => { setUserDataEditMode(true); changeUserDetails(); }}>Edit Details</button>}
            </div>
            <ThirtySpacer />
            <div className='DetailsinputFlex'>
                <div>
                    <span style={{ color: "white" }} className='InputLabels'>First Name</span>
                    <FiveSpacer />
                    <input
                        className='PersonalDetailsInput'
                        type="text"
                        placeholder={'First Name'}
                        value={userData.first_name}
                        disabled={!userDataEditMode}
                        onChange={(e) => handleInputChange('first_name', e.target.value)} />
                </div>
                <div>
                    <span style={{ color: "white" }} className='InputLabels'>Last Name</span>
                    <FiveSpacer />
                    <input
                        className='PersonalDetailsInput'
                        type="text"
                        placeholder='Last Name'
                        value={userData.last_name}
                        disabled={!userDataEditMode}
                        onChange={(e) => handleInputChange('last_name', e.target.value)} />
                </div>
            </div>
            <div>
                <div>
                    <span style={{ color: "white" }} className='InputLabels'>Email Address</span>
                    <FiveSpacer />
                    <input
                        className='PersonalDetailsEmailInput'
                        type="text"
                        placeholder='Email Address'
                        value={userData.email}
                        disabled={!userDataEditMode}
                        onChange={(e) => handleInputChange('email', e.target.value)} />
                </div>
                <div>
                    <span style={{ color: "white" }} className='InputLabels' >Cell Phone Number</span>
                    <FiveSpacer />
                    <input
                        className='PersonalDetailsEmailInput'
                        type="text"
                        placeholder='Cell Phone Number'
                        value={userData.phone_main}
                        disabled={!userDataEditMode}
                        onChange={(e) => handleInputChange('phone_main', e.target.value)} />
                </div>
            </div>
        </>
    );


};

export default PersonalDetails;