import React, { useState } from 'react';

// CSS Files
import './../App.css';
import NavBar from './NavBar';
import { HundredSpacer, TenSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from './Footer';
import PersonalDetails from './PersonalDetails';
import ContactUs from './ContactUs';
import ContactUsV2 from './ContactUsV2';
import FooterV2 from './FooterV2';
import InquiryModal from './Modals/InquiryModal';

// Components

// Styled Components

// SVGS

// Icons

// Pages

const Revisions: React.FC = () => {
    const [inquirymodalIsOpen, setinquiryModalIsOpen] = useState(false);
    const [productSelected, setProductSelected] = useState("");

    const openModal = () => {
        setinquiryModalIsOpen(true);
    };

    const closeModal = () => {
        setinquiryModalIsOpen(false);
    };
    return (
        <>
            <InquiryModal isOpen={inquirymodalIsOpen} onClose={closeModal} productText={productSelected} />

            <div className="PackagesSectionTabsInner">
                <div className='PackagesFlex'>
                    <div className='PackagesDiv' >
                        <div className='PackagesInnerDiv'>
                            <span className='PackagesHOne'>Basic <br /> <span className='LandingPageHOneOrange'>Package</span>.</span>
                            <TenSpacer />
                            <span className='listStyling'>This package includes the following:</span>
                            <div className="listContainer">

                                <ul className='listStyling'>
                                    <li>Contact form setup.</li>
                                    <li>Basic SEO optimization.</li>
                                    <li>Hosting recommendations.</li>
                                    <li>Up to 3 pages of website content and design.</li>
                                    <li>Necessary plugins for essential functionality.</li>
                                    <li>Responsive design for optimal display on various devices.</li>
                                    <li>Basic admin panel for content management.</li>
                                    <li>Basic website designs and templates.</li>
                                </ul>
                            </div>
                            <TenSpacer />
                            {/* <span className='PriceStyling'>Base Price:</span><span className='PriceStylingSmall'>R7,000</span> */}
                            <button className='EnquireBtn' onClick={() => { openModal(); setProductSelected('Basic WebDesignPackage') }}>Enquire</button>
                        </div>
                    </div>
                    <div className='PackagesDiv'>
                        <div className='PackagesInnerDiv'>
                            <span className='PackagesHOne'>Standard <br /> <span className='LandingPageHOneOrange'>Package</span>.</span>
                            <TenSpacer />
                            <span className='listStyling'>This package includes the following:</span>
                            <div className="listContainer">
                                <ul className='listStyling'>
                                    <li>Contact form setup.</li>
                                    <li>SEO optimization.</li>
                                    <li>Hosting recommendations plus out hosting options.</li>
                                    <li>Up to 6 pages of website content and design.</li>
                                    <li>Necessary plugins for essential functionality.</li>
                                    <li>Responsive design for optimal display on various devices.</li>
                                    <li>Admin panel for content management.</li>
                                    <li>Website designs and templates.</li>
                                </ul>
                            </div>
                            <TenSpacer />
                            {/* <span className='PriceStyling'>Base Price:</span><span className='PriceStylingSmall'>R7,000</span> */}
                            <button className='EnquireBtn' onClick={() => { openModal(); setProductSelected('Basic WebDesignPackage') }}>Enquire</button>
                        </div>
                    </div>
                    <div className='PackagesDiv'>
                        <div className='PackagesInnerDiv'>
                            <span className='PackagesHOne'>Premium <br /> <span className='LandingPageHOneOrange'>Package</span>.</span>
                            <TenSpacer />
                            <span className='listStyling'>This package includes the following:</span>
                            <div className="listContainer">

                                <ul className='listStyling'>
                                    <li>Contact form setup.</li>
                                    <li>SEO optimization.</li>
                                    <li>Hosting recommendations plus out hosting options.</li>
                                    <li>No page count limit.</li>
                                    <li>Necessary plugins for essential functionality.</li>
                                    <li>Responsive design for optimal display on various devices.</li>
                                    <li>Admin panel for content management.</li>
                                    <li>Full Website designs and templates.</li>
                                </ul>
                            </div>
                            <TenSpacer />
                            {/* <span className='PriceStyling'>Base Price:</span><span className='PriceStylingSmall'>R7,000</span> */}
                            <button className='EnquireBtn' onClick={() => { openModal(); setProductSelected('Basic WebDesignPackage') }}>Enquire</button>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default Revisions;