import { useEffect, useRef, useState, useCallback } from 'react';

// CSS Files
import './../App.css';

// Components
import LandingSection from '../components/LandingSection';
import OurVaulesSection from '../components/OurValuesSection';
import ServiceAndProducts from '../components/ServicesAndProductsSection';
import AboutOurTeam from '../components/OurClients';
import ContactUs from '../components/ContactUs';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Document, Page, pdfjs } from "react-pdf";
// import 'react-pdf/dist/Page/AnnotationLayer.css';
// import 'react-pdf/dist/Page/TextLayer.css';
import Particles from 'react-particles';
import { loadSlim } from "tsparticles-slim";
import type { Container, Engine } from "tsparticles-engine";
import NavBar from '../components/NavBar';
import { TenSpacer, TwenetySpacer } from '../styledcomponents/spacers';
import FooterV2SinglePage from '../components/FooterV2SinglePage';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Styled Components

// SVGS

// Icons

// Pages

export default function ShareView() {
    const [base64CF, setbase64CF] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [files, setFiles] = useState<any>();
    const [numPages, setNumPages] = useState<any>(null);
    const [pageNumber, setPageNumber] = useState<any>(1);
    const [passwordRequired, setPasswordRequired] = useState<any>(false);
    const [emailRequired, setEmailRequired] = useState<any>(false);
    const [passwordShared, setPasswordShared] = useState<any>(null);
    const [EmailShared, setEmailShared] = useState<any>(null);
    const [linkExpired, setLinkExpired] = useState<any>(false);
    const [sharedFilesData, setSharedFilesData] = useState<any>(null);
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);
    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // console.log(container);
    }, []);


    async function GetFiles() {
        if (!queryParameters.get("token") || !queryParameters.get("token")) {
            // setShareToken(queryParameters.get("token"));
            return
        }
        let urlEmail = null;
        if (queryParameters.get("email")) {
            setEmailShared(queryParameters.get("email"));
            urlEmail = queryParameters.get("email")
        }

        let domain = process.env.REACT_APP_API_URL_FMS;
        let password = passwordShared ? "password=" + passwordShared : ""

        let emailString = EmailShared ? "email=" + EmailShared : "";
        if (!EmailShared && urlEmail) {
            emailString = "email=" + urlEmail
        }
        console.log(EmailShared, urlEmail, passwordShared);

        let AddSign = EmailShared && passwordShared ? "&" : ""

        let combinedString = password + AddSign + emailString

        let endpoint = `${domain}v1/media/sharelink/${queryParameters.get("token")}?` + combinedString;
        const response = await axios.get(endpoint);
        if (typeof response.data.statusCode !== 'undefined' && response.data.statusCode === 0) {
            // console.log(response.data.data)
            let temp = response.data.data.files
            if (typeof temp === 'string') {
                temp = JSON.parse(response.data.data.files)
            }
            setFiles(temp)
            setSharedFilesData(response.data.data)
            setLinkExpired(response.data.data.isExpired)
            setPasswordRequired(false);
            setEmailRequired(false)
        } else if (response.data.statusCode && response.data.statusCode === 3) {
            if (response.data.description.toLowerCase().includes("password") || response.data.description.toLowerCase().includes("IncorrectPassword")) {

                setPasswordRequired(true);
                setEmailRequired(true)
            } else {
                setEmailRequired(true)
            }
        }
    }

    async function downloadFile(fileId: any) {
        if (!queryParameters.get("token") || !queryParameters.get("token")) {
            // setShareToken(queryParameters.get("token"));
            return
        }
        let domain = process.env.REACT_APP_API_URL_FMS;
        let endpoint = `${domain}v1/media/sharelink/download/${queryParameters.get("token")}`;
        const response = await axios.post(endpoint,
            {
                file_id: fileId,
                "email": EmailShared,
                "password": passwordShared
            });
        if (typeof response.data.statusCode !== 'undefined' && response.data.statusCode === 0) {
            // console.log(response.data.data);
            let uint8Array = Uint8Array.from(atob(response.data.data), (c) => c.charCodeAt(0));

            // Create a Blob from the Uint8Array
            let blob = new Blob([uint8Array], { type: 'application/pdf' });

            // Create a data URL from the Blob
            let myBase64StringSeconodView = URL.createObjectURL(blob);
            setbase64CF(myBase64StringSeconodView)
        } else {

        }
    }
    const initialized = useRef(false)


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            GetFiles()

        }
    }, [])

    function getFileNameFromPath(filePath: string) {
        // Split the path using '/' as the delimiter
        const parts = filePath.split('/');
        // Get the last part which represents the file name
        const fileName = parts[parts.length - 1];
        return fileName;
    }

    async function downloadFileToComputer(file: any) {
        console.log(file)

        if (!queryParameters.get("token") || !queryParameters.get("token")) {
            // setShareToken(queryParameters.get("token"));
            return
        }
        let domain = process.env.REACT_APP_API_URL_FMS;
        let endpoint = `${domain}v1/media/sharelink/download/${queryParameters.get("token")}`;
        const response = await axios.post(endpoint,
            {
                file_id: file.id,
                "email": EmailShared,
                "password": passwordShared
            });
        if (typeof response.data.statusCode !== 'undefined' && response.data.statusCode === 0) {
            // console.log(response.data.data);

            const byteCharacters = atob(response.data.data);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            let blodNew = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blodNew);

            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = getFileNameFromPath(file.key);
            document.body.appendChild(a);
            a.click();

            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);


        } else {

        }
    }



    return (
        <>
            <div className='main'>
                <Particles className='testparticlesShareview'
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={
                        {
                            fullScreen: false,
                            fps_limit: 24,
                            particles: {
                                number: { value: 160, density: { enable: true, value_area: 800 } },
                                color: { value: "#ffffff" },
                                shape: {
                                    type: "circle",
                                    stroke: { width: 0, color: "#000000" },
                                    polygon: { nb_sides: 5 },
                                    image: { src: "img/github.svg", width: 100, height: 100 }
                                },
                                opacity: {
                                    value: 0.7,
                                    random: true,
                                    anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
                                },
                                size: {
                                    value: 2.5,
                                    random: true,
                                    anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
                                },
                                line_linked: {
                                    enable: false,
                                    distance: 30,
                                    color: "#ffffff",
                                    opacity: 0.4,
                                    width: 1
                                },
                                move: {
                                    enable: true,
                                    speed: 0.2,
                                    direction: "none",
                                    random: true,
                                    straight: false,
                                    out_mode: "out",
                                    bounce: false,
                                    attract: { enable: false, rotateX: 600, rotateY: 600 }
                                }
                            },
                            interactivity: {
                                detect_on: "canvas",
                                events: {
                                    onhover: { enable: false, mode: "bubble" },
                                    onclick: { enable: false, mode: "repulse" },
                                    resize: false
                                },
                                modes: {
                                    grab: { distance: 400, line_linked: { opacity: 1 } },
                                    bubble: { distance: 250, size: 0, duration: 2, opacity: 0, speed: 3 },
                                    repulse: { distance: 400, duration: 0.4 },
                                    push: { particles_nb: 4 },
                                    remove: { particles_nb: 2 }
                                }
                            },
                            retina_detect: true
                        }}
                />
                <div className='ShareViewLandingSection '>
                    <div className='ShareViewLandingSectionOuter'>
                        <NavBar />
                        <div className='ShareLandingSectionInner'>
                            <div className='LoginDiv'>
                                {emailRequired &&
                                    <>
                                        <h1 className='LandingPageHOne'>Welcome <span className='LandingPageHOneOrange'>to the Vault!</span></h1>
                                        <span className='LandingPageSpan'>To access the documents shared with you, enter your email address and the password provided.</span>
                                        <TwenetySpacer />
                                        <TenSpacer />
                                        <input type="text"
                                            className='ContactInput'
                                            value={EmailShared}
                                            placeholder="Email"
                                            onChange={(e) => { setEmailShared(e.target.value) }}
                                        />
                                    </>
                                }
                                {passwordRequired && <input type="password"
                                    className='ContactInput'
                                    value={passwordShared}
                                    placeholder="Enter Password"
                                    onChange={(e) => { setPasswordShared(e.target.value) }}
                                />}
                                {(emailRequired || passwordRequired) && <button className='ContactBtn' onClick={GetFiles}> Confirm</button >}
                            </div>

                            {
                                linkExpired ?
                                    <>Link Expired</>
                                    :
                                    <>
                                        <div className='FileShareFlex'>
                                            <div>
                                                {base64CF && <>
                                                    <div className='FileShareRight'>
                                                        <div className='FileInfoViewFlex'>
                                                            <div>
                                                                <span className='FileShareFiles'>You are currently viewing: <span>{getFileNameFromPath(selectedFile.key)}</span></span>
                                                            </div>
                                                            <div className="controls">
                                                                <button className='ShareFilesButton' onClick={prevPage} disabled={pageNumber === 1}>
                                                                    Prev
                                                                </button>
                                                                <button className='ShareFilesButton' onClick={nextPage} disabled={pageNumber === numPages}>
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <TenSpacer />
                                                        <Document
                                                            file={base64CF}
                                                            onLoadSuccess={onDocumentLoadSuccess}
                                                            onContextMenu={(e) => e.preventDefault()}
                                                            className="pdfContainer"
                                                        >

                                                            <Page pageNumber={pageNumber} />
                                                        </Document>

                                                    </div>
                                                </>
                                                }
                                            </div>
                                            <div className='FileShareLeft'>
                                                {EmailShared && files && <>
                                                    <h1 className='LandingPageHOne'>Welcome <span className='LandingPageHOneOrange'>to the Vault!</span></h1>
                                                    <span className='LandingPageSpan'>The below documents have been shared with you!</span>
                                                    <TenSpacer />
                                                    <hr className='hrOrange' />
                                                    <TwenetySpacer />
                                                </>}
                                                {files && files.map((file: any) => {
                                                    return (

                                                        <>
                                                            {file.isfile ?
                                                                <>
                                                                    <div className='ShareFileSelectFlex'>
                                                                        <div className='ShareFileInnerFlex'>
                                                                            {/* <span className='FileShareFiles'>{file.id}</span>   */}
                                                                            <span className='FileShareFiles'>{file.key.replace(/\//g, " -> ")}</span>
                                                                        </div>
                                                                        <div className='ShareFileInnerFlex'>
                                                                            <button className='ShareFilesButton' onClick={() => { downloadFile(file.id); setSelectedFile(file) }}>View</button>
                                                                            {sharedFilesData.download ?
                                                                                <button className='ShareFilesButton' onClick={() => { downloadFileToComputer(file) }}>Download</button>
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                    </div>
                                                                </> :
                                                                <>
                                                                    {/* <span className='FileShareFiles'>{file.id}</span>
                                                                <span className='FileShareFiles'>{file.key}</span> */}
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                })}</div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    <FooterV2SinglePage />
                </div>
            </div>
        </>
    );
};

;