import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AirlaFooter } from './../imgs/FootericonAni.svg';
import './../App.css';
import { AiFillLinkedin, AiFillTwitterSquare, AiFillFacebook } from 'react-icons/ai';
import { FiveSpacer, TenSpacer } from '../styledcomponents/spacers';
export default function FooterV2() { //test
  const menuData = [
    {
      category: 'Quick Links',
      links: [

        { label: 'Airvidence Packages', url: '/ourofferings' },
        { label: 'Enquire', url: '/contactus' },
        { label: 'The Forge', url: '/aboutus' },
        { label: 'Login', url: '/onboarding' }
      ]
    },
    // Add more categories as needed
  ];

  const menuData2 = [
    {
      category: 'Services',
      links: [
        { label: 'Packages', url: '/ourofferings' },
        { label: 'Web Development', url: '/ourofferings' },
        { label: 'Web Hosting', url: '/ourofferings' }
      ]
    },
    // Add more categories as needed
  ];

  const menuData3 = [
    {
      category: 'Social',
      links: [
        { label: 'Linkedin', url: '' },
        { label: 'Instagram', url: '' },
        { label: 'Twitter', url: '' }
      ]
    },
    // Add more categories as needed
  ];

  return (
    <>
      <div className='NewFooterContainer'>
        <div className='FlexFooterContainer'>
          <div className='LeftMainContainer'>
            <div className='LeftContainerFooterOuter'>
              <AirlaFooter className='footLogo' />
              <div className='LeftContainerFooter2'>
                {menuData.map((category, index) => (
                  <div key={index}>
                    <h2 className='FooterHeader'>{category.category}</h2>
                    <ul className='UIStyling'>
                      {category.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <a className='LinksText' href={link.url}>{link.label}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                {menuData2.map((category, index) => (
                  <div key={index}>
                    <h2 className='FooterHeader'>{category.category}</h2>
                    <ul className='UIStyling'>
                      {category.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <a className='LinksText' href={link.url}>{link.label}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                {menuData3.map((category, index) => (
                  <div key={index}>
                    <h2 className='FooterHeader'>{category.category}</h2>
                    <ul className='UIStyling'>
                      {category.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <a className='LinksText' href={link.url}>{link.label}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='RightContainerFooter'>
            <div className='RightContainerFooterInner'>
              <span className='FooterSmallSpans'>Get in Touch</span>
              <FiveSpacer />
              <span className='FooterHeaderSpans'>Feel free to send us your email and we will get in touch!</span>
              <TenSpacer />
              <div className='InputBtnFlex' ><input placeholder="Please Enter your email address" className='ContactFooterInput' /><button className='ContactUsFooterButton'>Submit</button></div>
            </div>
          </div>
        </div>
        <div className='BottomFooterSection'>
          <div className='BottomTextFlex'>
            <a className='SubtextFooterSpan'>© 2024 AirlaForge</a>
            <a className='SubtextFooterSpan'>Support</a>
            <a className='SubtextFooterSpan'>Privacy Policy</a>
            <a className='SubtextFooterSpan'>Terms of Use</a>
            <a className='SubtextFooterSpan'>Cookie Policy</a>
          </div>
        </div>
      </div>
    </>
  );
};

