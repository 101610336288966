import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home'
import AccountVerification from './pages/AccountVerification'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/NotFound';
import ShareView from './pages/ShareView';
import LoginNRegister from './pages/LoginNRegister';
import Packages from './pages/Packages';
import MyAccount from './pages/MyAccount';
import AboutUs from './pages/AboutUs';
import OurWork from './pages/OurWork';
import ContactUs from './pages/ContactUs';
import ErrorBoundary from './pages/ErrorBoundary';
import Client from './pages/Client';

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route path='/' element={<><Home /></>} />
          <Route path='/myaccount' element={<MyAccount />} />
          <Route path='/onboarding' element={<LoginNRegister />} />
          <Route path='/ourofferings' element={<Packages />} />
          {/* <Route path='/aboutus' element={<AboutUs />} /> */}
          {/* <Route path='/contactus' element={<ContactUs />} /> */}
          {/* <Route path='/ourwork' element={<OurWork />} /> */}
          <Route path='/accountverification' element={<AccountVerification />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/airvidence/shareview/*' element={<ShareView />} />
          <Route path='/client' element={<Client />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </ErrorBoundary>

  );
}

export default App;
