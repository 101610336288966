import React, { useEffect, useState } from 'react';

import './../App.css';

// Icons
import { AiOutlineMobile, AiOutlineDesktop, AiOutlineEdit, AiOutlineHistory, AiOutlineLock, AiOutlineSearch, AiOutlineStock } from 'react-icons/ai';
import { FiftySpacer, FiveSpacer, HundredSpacer, TenSpacer, TwenetySpacer } from '../styledcomponents/spacers';
import Footer from './Footer';
import { LoginRequest, sendForgotPassword } from '../Requests/LoginNRegister'
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';

export default function Login({ setShowRegister }: any) {
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [failedLogin, setFailedLogin] = useState(false);
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
    const [forgotPasswordLinkSent, setForgotPasswordLinkSent] = useState(false);

    const [formData, setFormData] = useState({
        password: '',
        emailAddress: '',
    });

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async () => {
        // e.preventDefault();
        console.log(formData);
        setIsLoading(true);
        setFailedLogin(false);
        let statusCheck = await LoginRequest(formData);
        setIsLoading(false);
        // 
        if (statusCheck) {
            nav("/myaccount");
        } else {
            setFailedLogin(true);
        }
    };
    const handleResetSubmit = async () => {
        console.log(formData);
        setForgotPasswordLinkSent(false)
        setIsLoading(true);
        setFailedLogin(false);
        let statusCheck = await sendForgotPassword(formData);
        setIsLoading(false);

        if (statusCheck) {
            setForgotPasswordLinkSent(true)
        } else {
            setFailedLogin(true);
        }
    };

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                // handleSubmit();
                const button = document.getElementById('submitButton');
                button?.click();
                event.preventDefault();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    return (
        <>
            <div className='LoginDiv'>
                <h1 className='LandingPageHOne'>Welcome <span className='LandingPageHOneOrange'>Back!</span></h1>
                <span className='LandingPageSpan' >Please enter your credentials to access your account.</span>
                <TwenetySpacer />
                <TenSpacer />
                {/* <label htmlFor="emailAddress">Email Address:</label> */}
                <input
                    type="email"
                    className='ContactInput'
                    id="emailAddress"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    placeholder='Email Address'
                />
                {!forgotPasswordOpen && <input
                    type="password"
                    className='ContactInput'
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder='Password'
                />}

                {/* <div className='checkboxflex'>
    <input
        className='checkbox'
        type="checkbox"
        id="subscribeNewsletter"
        name="subscribeNewsletter"
        checked={formData.subscribeNewsletter}
        onChange={handleChange}
    />
    <label className='checkboxLabel' htmlFor="LandingPageHOneOrange">Subscribe to newsletters</label>
</div> */}
                {isLoading ? <button className='ContactBtn' type="submit" >Loading...</button>
                    :
                    <>
                        {forgotPasswordOpen ? <button id="submitButton" className='ContactBtn' type="submit" onClick={handleResetSubmit}>Reset password</button>
                            :
                            <button id="submitButton" className='ContactBtn' type="submit" onClick={handleSubmit}>Login</button>}
                    </>
                }
                <FiveSpacer />
                {failedLogin && <span style={{ color: "red" }}>Password Or Email is Incorrect</span>}
                {forgotPasswordLinkSent && <span style={{ color: "green" }}>Reset Link has been sent to your email.</span>}
                <FiveSpacer />
                <div className='HelpTextDiv'>
                    <span className='LandingPageSpan'>Don't have an account?
                        <span className='SignUpLink'
                            onClick={() => { setShowRegister(true) }}>
                            Sign Up Here
                        </span>
                    </span>
                    {forgotPasswordOpen ?
                        <span className='SignUpLink'
                            onClick={() => { setForgotPasswordOpen(false) }}>
                            Return to login?
                        </span>
                        : <span className='SignUpLink'
                            onClick={() => { setForgotPasswordOpen(true) }}>
                            Forgot your password?
                        </span>}
                    {/* <a className='SignUpLink'> Need Help? </a> */}
                </div>
            </div>

        </>
    );
};

