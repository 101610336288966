import React, { useCallback, useState } from 'react';

// CSS Files
import './../App.css';

// Components
import Login from '../components/Login';
import Register from '../components/Register';
import NavBar from '../components/NavBar';

// Styled Components

// SVGS
import { ReactComponent as RightImage } from './../imgs/LoginImage.svg';
import { loadSlim } from 'tsparticles-slim';
import type { Container, Engine } from "tsparticles-engine";
import Particles from 'react-particles';

// Icons

// Pages

export default function LoginNRegister({ isRegister = false }: any) {
    const [showRegister, setShowRegister] = useState(isRegister);

    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // console.log(container);
    }, []);

    return (
        <>
            <div className="main">
                <Particles className='onePageParticles'
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}

                    options={{
                        fullScreen: true,
                        particles: {
                            number: { value: 120, density: { enable: true, value_area: 1000 } },
                            color: { value: "#ffffff" },
                            shape: {
                                type: "circle",
                                stroke: { width: 0, color: "#000000" },
                                polygon: { nb_sides: 5 },
                                image: { src: "img/github.svg", width: 100, height: 100 }
                            },
                            opacity: {
                                value: 1,
                                random: true,
                                anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
                            },
                            size: {
                                value: 2.5,
                                random: true,
                                anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
                            },
                            move: {
                                enable: true,
                                speed: 0.2,
                                direction: "none",
                                random: true,
                                straight: false,
                                out_mode: "out",
                                bounce: false,
                                attract: { enable: false, rotateX: 600, rotateY: 600 }
                            }
                        },
                        retina_detect: true
                    }}
                />
                <div className="LandingSection">
                    <div className="LandingSectionOuter">
                        <NavBar />
                        <div className="LoginRegisterInner">

                            {showRegister ?
                                <>
                                    <div className='RightRegisterFlexDiv'>
                                        <Register setShowRegister={setShowRegister} />
                                    </div>
                                </>
                                :
                                <>
                                    <div className='RightLoginFlexDiv'>
                                        <Login setShowRegister={setShowRegister} />
                                    </div>
                                </>
                            }
                            {/* <RightImage className='RightLoginImage' /> */}
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

