import React from 'react';

// CSS Files
import './../App.css';
import NavBar from '../components/NavBar';
import { HundredSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from '../components/Footer';
import PersonalDetails from '../components/PersonalDetails';
import ContactUs from './ContactUs';
import ContactUsV2 from './ContactUsV2';
import FooterV2 from './FooterV2';

// Components

// Styled Components

// SVGS

// Icons

// Pages

const Revisions: React.FC = () => {
    return (
        <>
            <div className='PackagesSectionOuter'>
                <h2 className='LandingPageHOneH2'>How we deal with <span className='LandingPageHOneOrange'>Revisions</span></h2>
                <span className='LandingPageSpan'>At Airla Forge, we understand that the development process is dynamic, and clients may have revisions or changes to the initial project scope. We strive to accommodate reasonable requests for modifications to ensure client satisfaction. However, it is important to establish a clear understanding of the implications of such changes, both in terms of timeline and pricing. The process for revisions and changes is as follows. </span>
                <h2 className='LandingPageHOneH2'>Change Requests </h2>
                <span className='LandingPageSpan'>If a client wishes to request revisions or changes to the agreed-upon project scope, we encourage open communication to discuss the desired modifications. Our team will evaluate the feasibility and impact of these changes, considering factors such as development complexity, resource allocation, and timeline adjustments.
                    ving clients a comprehensive understanding of the financial implications.
                </span>
                <h2 className='LandingPageHOneH2'>Assessment and Agreement </h2>
                <span className='LandingPageSpan'>Upon receiving a change request, we will assess its impact on the project and promptly provide the client with an updated proposal, including any adjustments to the timeline and cost. This proposal will outline the revised scope of work, associated costs, and any potential implications for the project timeline. It is crucial for both parties to review and agree upon the proposed changes before proceeding.

                </span>
                <h2 className='LandingPageHOneH2'>Revised Timeline </h2>
                <span className='LandingPageSpan'>Depending on the nature and extent of the requested changes, the project timeline may need to be adjusted. Our team will provide a revised timeline, taking into account the additional development efforts required. We prioritize transparency in communicating any potential delays caused by change requests, ensuring that clients have a clear understanding of the revised timeline.
                </span>

                <h2 className='LandingPageHOneH2'>Revised Cost </h2>
                <span className='LandingPageSpan'>Revisions to the project scope may impact the overall cost of the project. If the changes result in additional development work or require the utilization of additional resources, a corresponding adjustment to the pricing will be provided. Our team will clearly outline the revised cost in the updated proposal, giving clients a comprehensive understanding of the financial implications. </span>

                <h2 className='LandingPageHOneH2'>Scope Change Agreement </h2>
                <span className='LandingPageSpan'>To ensure a mutual understanding and agreement on scope changes, we require clients to provide written approval before implementing any modifications. This agreement acts as a formal acknowledgment of the revised scope, timeline, and associated costs. By having a documented approval process, we can maintain clarity and avoid any potential misunderstandings.

                    At Airla Forge, we value our clients' satisfaction and are committed to delivering projects that meet their unique requirements. We understand that revisions and changes can arise during the development process. By establishing a transparent process for evaluating and implementing scope changes, we aim to maintain effective communication and ensure that projects progress smoothly while keeping clients informed every step of the way. </span>
            </div >
            <HundredSpacer />
        </>
    );
};

export default Revisions;