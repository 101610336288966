import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AirlaLogo } from './../imgs/Group 428.svg';
import './../App.css';
import { AiFillLinkedin, AiFillTwitterSquare, AiFillFacebook, AiOutlineUser } from 'react-icons/ai';
import { FifteenSpacer, FiftySpacer, HundredSpacer, TenSpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import NavBar from './NavBar';

export default function AboutOurTeam() {


  return (
    <>
      <div className='OurClients'>
        
      </div>
    </>
  );
};

