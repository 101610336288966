import React, { useEffect, useState } from 'react';

// CSS Files
import './../App.css';
import NavBar from '../components/NavBar';
import { FiveSpacer, HundredSpacer, TenSpacer, ThirtySpacer, TwenetySpacer } from '../styledcomponents/spacers';
import { AiFillFacebook, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { ReactComponent as TempRightImage } from './../imgs/RightImageV2.svg';
import Footer from '../components/Footer';
import PersonalDetails from '../components/PersonalDetails';
import axios from 'axios';
import PaymentModal from './Modals/PaymentModal';
import { putCancelSubscriptionFMS } from '../Requests/Account';
import LinearProgress from '@mui/material/LinearProgress';
// Components

// Styled Components

// SVGS

// Icons

// Pages
interface Organization {
    id: number;
    name: string;
    numberOfusers: number;
    role: string;
    isActive: Boolean;
    paid_until: string;
    created_at: string;
    isAdmin: Boolean;
    transaction_token: string;
    status: string;
    status_code: number;
}

const AccountSubscription: React.FC = () => {
    const [orgList, setOrgList] = useState<Organization[]>([]);
    const [selectedOrg, setselectedOrg] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(true);

    const [paymentmodalIsOpen, setpaymentModalIsOpen] = useState(false);
    const openPaymentModal = () => {

        setpaymentModalIsOpen(true);
    };

    const closePaymentModal = () => {
        setpaymentModalIsOpen(false);
    };


    useEffect(() => {
        getOrg();
    }, []);

    return (
        <>
            <PaymentModal isOpen={paymentmodalIsOpen} onClose={closePaymentModal} selectedOrg={selectedOrg} comeRenew={true} selectedPayment={null} />

            <ThirtySpacer />
            <span className='TableHeader'>Your Organisations</span>
            <TenSpacer />
            <FiveSpacer />
            {orgList && orgList.length > 0 ? (
                <table>
                    <thead className="headerRow">
                        <tr className="orgTableHeaders">
                            <th className='TableOrgNameHeader'>Organisation Name</th>
                            <th className='TableOrgNoMembers'>Members</th>
                            <th className='TableOrgNoMembers'>Your Role</th>
                            <th className='TableOrgCreatedOn'>Created On</th>
                            <th className='TableOrgNoMembers'>IsActive</th>
                            <th className='TableOrgNSubscriptionEnd'>End of Subscription </th>
                            <th className='TableOrgNoMembers'>Days Remaining</th>
                            {/* <th className='TableOrgNoMembers'>Renew</th> */}
                            <th className='TableOrgNoMembers'>Status</th>
                            <th className='TableOrgNoMembers'>Cancel</th>
                        </tr>
                    </thead>
                    <tbody className="orgTableText">
                        {orgList.map((organization) => (
                            <tr key={organization.id} className={organization.id % 2 === 0 ? "orgMainTableTextLightGrey" : "orgMainTableTextDarkGrey"}>
                                {organization.isAdmin && <>
                                    <td className='TableOrgPayDataColumn'>{organization.name}</td>
                                    <td className='TableOrgPayDataColumnUsers'>{organization.numberOfusers}</td>
                                    <td className='TableOrgPayDataColumn'>{organization.role.replace(organization.name + ' -','').trim()}</td>
                                    <td className='TableOrgPayDataColumn'>{new Date(organization.created_at).toLocaleString()}</td>
                                    <td className='TableOrgPayDataColumn'>{organization.isActive ? <span className='TableOrgPayDataColumn SucText'>Paid</span> : <><span className='TableOrgPayDataColumn ErrorText'>UnPaid </span></ >}</td>
                                    <td className='TableOrgPayDataColumn'>{
                                        organization.paid_until ?
                                            <>
                                                {organization.isActive ?
                                                    <span className='TableOrgPayDataColumn SucText'>{new Date(organization.paid_until).toLocaleString()}</span>
                                                    : <span className='TableOrgPayDataColumn WarningText'>{new Date(organization.paid_until).toLocaleString()}
                                                    </span>}
                                            </>
                                            : <span className='TableOrgPayDataColumn ErrorText'>------</span>}</td>
                                    <td className='TableOrgPayDataColumn'>{daysRemainingUntilSubscriptionEnds(organization.paid_until)} Days Left</td>
                                    {/* <td className='TableOrgPayDataColumn'>
                                        {organization.isActive ?
                                            <>
                                                {(organization.transaction_token && organization.status_code === 1) ? <span className='TableOrgPayDataColumn SucText'>Renewed</span> : <span className='TableOrgPayDataColumn WarningText'>Will Expire</span>}
                                            </>
                                            :
                                            <>
                                                <span className='TableOrgPayDataColumn ErrorText'>Not Renewed</span>
                                            </>}
                                    </td> */}
                                    <td className='TableOrgPayDataColumn'>{organization.status}</td>
                                    <td className='TableOrgPayDataColumn'>
                                        {(organization.transaction_token && organization.status_code === 2) || !organization.transaction_token ?
                                            <>
                                                {organization.transaction_token ? <button className='SubscriptionPayBtn' onClick={() => { setselectedOrg(organization.id); openPaymentModal() }}>Resubscribe </button>
                                                    : <button className='SubscriptionPayBtn' onClick={() => { setselectedOrg(organization.id); openPaymentModal() }}>Pay </button>
                                                }
                                            </>

                                            : <button className='SubscriptionPayBtn' onClick={() => { putCancelSubscriptionFMS(organization.transaction_token) }}>Cancel</button>}
                                    </td>


                                    {/* <td>{renderActions(organization.id)}</td> */}
                                </>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table >
            ) : (
                <>{isLoading ? <LinearProgress variant="query" />
                    :
                    <span style={{ display: "block", width: "100%", textAlign: "left", color: "red", }}>
                        <b>You do not own any organisations{/*, to create an organization, please <a onClick={openModal}>click here</a>*/}</b>
                    </span >}</>
            )}
            <ThirtySpacer />

        </>
    );

    async function getOrg() {
        setIsLoading(true)
        let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/corp/detailedlist`;
        let token = localStorage.getItem("Auth");
        let options = {
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(endpoint, options);
            if (response.data.statusCode === 0 && response.data.orgList && response.data.orgList.length > 0) {

                for (let i = 0; i < response.data.orgList.length; i++) {

                    if (response.data.orgList[i].transaction_token) {
                        response.data.orgList[i].status_code = 1
                        response.data.orgList[i].status = await getOrgActive(response.data.orgList[i].transaction_token);
                        if (response.data.orgList[i].status === "CANCELLED" || response.data.orgList[i].status === "NOT PAID") {
                            response.data.orgList[i].status_code = 2;
                        }
                    } else {
                        response.data.orgList[i].status_code = 2;
                        response.data.orgList[i].status = "NOT PAID"
                    }
                }
                // console.log(response.data.orgList)
                setOrgList(response.data.orgList);

            } else if (response.data.statusCode === 2) {
                setTimeout(() => {
                    getOrg();
                }, 2000);
            } else if (response.data && response.data.orgList.length < 1) {
                // openModal();
            }
            setIsLoading(false)

        } catch (e) {
            setIsLoading(false)
        }
    }

    async function getOrgActive(transtoken: string) {
        let endpoint = `${process.env.REACT_APP_API_URL_FMS}v1/payments/fetch/${transtoken}`;
        let token = localStorage.getItem("Auth");
        let options = {
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(endpoint, options);
            if (response.data.message && response.data.message.response && response.data.message.response.status_text) {

                return response.data.message.response.status_text;

            } else {
                return "NOT PAID"
            }

        } catch (e: any) {
            console.log(e.message)
        }
    }

    function daysRemainingUntilSubscriptionEnds(endDate: string) {

        const end = new Date(endDate);

        const today = new Date();
        const endMs = end.getTime();
        const todayMs = today.getTime();

        // Calculate the difference in milliseconds
        const differenceMs = endMs - todayMs;

        let daysRemaining = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        if (daysRemaining < 0) {
            daysRemaining = 0;
        }
        return daysRemaining;
    }


};


export default AccountSubscription;