// Modal.tsx
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import AirlaVault from '../AirlaVault';
import { AiFillCloseCircle } from 'react-icons/ai';
import { TwenetySpacer } from '../../styledcomponents/spacers';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode; // Add this line to include children
  comeRenew: any;
  selectedOrg: any;
  selectedPayment: any;
}

const CustomModal: React.FC<ModalProps> = ({ isOpen, onClose, children, comeRenew, selectedOrg, selectedPayment }) => {


  useEffect(() => {
    // Add a class to the body when the modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    // Cleanup function to remove the added class when the component unmounts
    return () => {
      document.body.style.overflow = 'visible';
    };

  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      className="PaymentModal"
      overlayClassName="Overlay"
    >
      <AiFillCloseCircle onClick={onClose} className='CloseButton'>Close Modal</AiFillCloseCircle>
      <div className="ContactTextContainer">
        <h1 className='InquiryHOne'>Ready to dive into a <span className='LandingPageHOneOrange'>file storage</span> revolution?</h1>
        <span className='InquirySpan'>Looking to pay for one of your organizations or create a new one? Please complete the form below.</span>
      </div>
      <TwenetySpacer />
      <AirlaVault comeRenew={comeRenew} selectedOrg={selectedOrg} selectedPayment={selectedPayment} />
    </Modal>
  );
};

export default CustomModal;
