import React, { useState } from 'react';

import './../App.css';

// Icons
import { AiOutlineMobile, AiOutlineDesktop, AiOutlineEdit, AiOutlineHistory, AiOutlineLock, AiOutlineSearch, AiOutlineStock } from 'react-icons/ai';
import { FiftySpacer, FiveSpacer, HundredSpacer, TenSpacer, TwenetySpacer } from '../styledcomponents/spacers';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { RegisterRequest } from '../Requests/LoginNRegister'

export default function Register({ setShowRegister }: any) {
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [failedLogin, setFailedLogin] = useState(false);

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: "",
        emailAddress: '',
        firstName: "",
        lastName: "",
        subscribeNewsletter: false

    });

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setFailedLogin(false);
        let statusCheck = await RegisterRequest(formData);
        setIsLoading(false);
        // 
        if (statusCheck) {
            setShowRegister(false)
        } else {
            setFailedLogin(true);
        }
    };

    return (
        <>
            <div className='LoginDiv'>
                <h1 className='LandingPageHOne'>Welcome to <span className='LandingPageHOneOrange'>AirlaForge</span></h1>
                <span className='LandingPageSpan'>Please fill in the below fields to create an account.</span>
                <TwenetySpacer />
                <TenSpacer />
                <input
                    type="text"
                    className='ContactInput'
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder='First Name'
                />
                {/* <label htmlFor="lastName">Last Name:</label> */}
                <input
                    type="text"
                    className='ContactInput'
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder='Last Name'
                />
                {/* <label htmlFor="emailAddress">Email Address:</label> */}
                <input
                    type="email"
                    className='ContactInput'
                    id="emailAddress"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    placeholder='Email Address'
                />
                <input
                    type="password"
                    className='ContactInput'
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder='Password'
                />
                <input
                    type="password"
                    className='ContactInput'
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder='Confirm Password'
                />
                {isLoading ? <button className='ContactBtn' type="submit" >Loading...</button>
                    : <button className='ContactBtn' type="submit" onClick={handleSubmit}>Register</button>}
                {failedLogin && <span style={{ color: "red" }}>Failed to Create Account.</span>}
                <FiveSpacer />
                <div className="HelpTextDiv">
                    <div className='checkboxflex'>
                        <input
                            className='checkbox'
                            type="checkbox"
                            id="subscribeNewsletter"
                            name="subscribeNewsletter"
                            checked={formData.subscribeNewsletter}
                            onChange={handleChange}
                        />
                        <label className='LandingPageSpan' htmlFor="LandingPageHOneOrange">Subscribe to newsletters</label>
                    </div>
                    <span className='LandingPageSpan'>Have an account? <span className='SignUpLink'
                        onClick={() => { setShowRegister(false) }}> Login Here</span></span>
                </div>
            </div>
        </>
    );
};

